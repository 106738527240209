import { FormEditMode, AssessmentTypes, RiskAssessmentStatus, SeverityDescription } from "../../helpers/Constants";
import { Button, Grid, Paper, TextField, Typography, Autocomplete, Box, IconButton, FormControlLabel, Checkbox, MenuItem, Divider } from "@mui/material";
import { getAssessmentTypeString, formatDateyyyymmdd, getUserFullName } from "../../helpers/Utils"
import { Save, SettingsBackupRestoreOutlined } from "@mui/icons-material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RiskAssessment } from "../../helpers/Interfaces";
import { RiskAssessmentDisplay } from "../../helpers/Constants";
import { useAppSelector } from "../../store/store";
import { useEffect, useState } from "react";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { RichTextEditor } from "../../layouts/RichTextEditor";
import { createRiskAssessment, updateRiskAssessment } from "../../api-services/RiskAssessmentApi";


interface Props {
    riskAssessment?: RiskAssessment | null | undefined
    formEditMode: FormEditMode
    onRiskAssessmentChange: (id: number, riskAssessment: RiskAssessment, formEditMode: FormEditMode, newRiskAssessmentAction: RiskAssessmentDisplay) => void
}

export function CreateEditRiskAssessment(props: Props) {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const cmdbUsersId = useAppSelector(state => state.cmdbUsersId.value);
    const emptyRiskAssessment: RiskAssessment = {
        id: 0, assessmentType: AssessmentTypes.AFFILIATE, assessmentName: "", description: "", identificationRespondersList: [], votingRespondersList: [], identificationStartDate: new Date(), notifyIdentificationRespondersByEmail: false, votingStartDate: new Date(), notifyVotingRespondersByEmail: false, createdBy: null, createdDate: null, updatedBy: null, updatedDate: null, identificationStartedBy: "", votingStartedBy: "", identificationEndDate: null, aggregationStartDate: null, aggregationStartedBy: null, numberOfRisksIdentified: null, votingEndDate: null, status: 0, approvalStartDate: null, approvalStartedBy: null,
        drillFilterStartDate: null, drillFilterEndDate: null, drillFilterEntitiesImpacted: [], identificationWelcomeMessage: "",
        allPermanentStaffIdentification: false, allStaffIdentification: false, minSeverityForVoting: 0, risks: []
    }
    //states
    const [riskAssessment, setRiskAssessment] = useState<RiskAssessment>(props.riskAssessment ?? emptyRiskAssessment)

    // #region effects

    useEffect(() => {// needed to restate riskAssessment if props.riskAssessment changes e.g. if we switch from editing one risk assessment to another
        if (props.formEditMode !== FormEditMode.CREATE && props.riskAssessment != null)
            setRiskAssessment(props.riskAssessment);
    }, [props.riskAssessment, props.formEditMode])

    // #endregion effects

    function handleCreateRiskAssessment() {
        createRiskAssessment(riskAssessment).then(result => {
            setRiskAssessment(result);
            props.onRiskAssessmentChange(result.id, result, FormEditMode.CREATE, RiskAssessmentDisplay.VIEW_RISKASSESSMENT);
        })
    }

    function handleUpdateRiskAssessment() {
        updateRiskAssessment(riskAssessment).then(result => {
            setRiskAssessment(result);
            props.onRiskAssessmentChange(result.id, result, FormEditMode.EDIT, RiskAssessmentDisplay.VIEW_RISKASSESSMENT);
        })
    }

    return (
        <Paper className="Form" >
            <Box className="FormHeader">
                <Typography className="FormHeaderOrFooterDetails"> {props.formEditMode === FormEditMode.CREATE ? "Create New Risk Assessment" : "Edit Risk Assessment"}</Typography>
                <div style={{ flexGrow: 1 }} />
                <IconButton size="large" onClick={() => setRiskAssessment(emptyRiskAssessment)} color="secondary" title="Reset Form"> <SettingsBackupRestoreOutlined />  </IconButton>
            </Box>
            <VerticalScrollableComponent viewPortHeightOffset={200}>
                <Grid id="CreateEditRiskAssessmentEditorContainer" container spacing={2} className="FormDetails">
                    <Grid item xs={12} sm={9}>
                        <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Name:</Typography>
                        <TextField fullWidth required name="assessmentName" type="text" variant="outlined" size="small"
                            value={riskAssessment.assessmentName ?? ''} onChange={(event) => setRiskAssessment(assessment => ({ ...assessment, assessmentName: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Assessment Type:</Typography>
                        <TextField fullWidth name="assessmentType" select variant="outlined" size="small"
                            value={riskAssessment.assessmentType ?? ''} onChange={event => setRiskAssessment(assessment => ({ ...assessment, assessmentType: Number(event.target.value) }))}
                            disabled={riskAssessment.status !== RiskAssessmentStatus.NEW || (props.formEditMode === FormEditMode.EDIT && riskAssessment.assessmentType === AssessmentTypes.DRILL)}>
                            <MenuItem value={AssessmentTypes.AFFILIATE}>{getAssessmentTypeString(AssessmentTypes.AFFILIATE)}</MenuItem >
                            <MenuItem value={AssessmentTypes.PROJECT}>{getAssessmentTypeString(AssessmentTypes.PROJECT)}</MenuItem >
                            <MenuItem value={AssessmentTypes.DRILL}>{getAssessmentTypeString(AssessmentTypes.DRILL)}</MenuItem >
                            <MenuItem value={AssessmentTypes.OTHERS}>{getAssessmentTypeString(AssessmentTypes.OTHERS)}</MenuItem >
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Description:</Typography>
                        <TextField fullWidth required name="description" type="text" variant="outlined" multiline size="small"
                            value={riskAssessment.description ?? ''} onChange={event => setRiskAssessment(assessment => ({ ...assessment, description: event.target.value }))} />
                    </Grid>

                    {riskAssessment.assessmentType === AssessmentTypes.DRILL ?
                        <>
                            <Grid item xs={12} ><br /><Typography color="secondary" > Drill Filter Setup </Typography> <Divider orientation="horizontal" color="red" style={{ borderBottomWidth: 1 }} /></Grid>
                            <Grid item xs={3} display="flex" container direction="column" justifyContent="center"><Typography color="textSecondary" > Risks submitted between </Typography> </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth required type="datetime-local" disabled={riskAssessment.status !== RiskAssessmentStatus.NEW} variant="outlined" size="small"
                                    value={formatDateyyyymmdd(riskAssessment.drillFilterStartDate) ?? ''} onChange={(event) => setRiskAssessment(assessment => ({ ...assessment, drillFilterStartDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                            </Grid>
                            <Grid item xs={1} display="flex" container direction="column" justifyContent="center" textAlign="center" ><Typography color="textSecondary" > to </Typography> </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth required type="datetime-local" disabled={riskAssessment.status !== RiskAssessmentStatus.NEW} variant="outlined" size="small"
                                    value={formatDateyyyymmdd(riskAssessment.drillFilterEndDate) ?? ''} onChange={(event) => setRiskAssessment(assessment => ({ ...assessment, drillFilterEndDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" >Entities Impacted:</Typography>
                                <Autocomplete disabled={riskAssessment.status !== RiskAssessmentStatus.NEW} ChipProps={{ color: "info" }} size="small" clearOnEscape multiple={true} options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                                    value={riskAssessment.drillFilterEntitiesImpacted} isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(event, value) => setRiskAssessment(assessment => ({ ...assessment, drillFilterEntitiesImpacted: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} ><br /><Typography color="secondary" > Risk Identification Setup </Typography> <Divider orientation="horizontal" color="red" style={{ borderBottomWidth: 1 }} /></Grid>
                            <Grid item xs={12} >
                                <Typography color="textSecondary" >Risk Identification Participants:</Typography>
                                <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape multiple={true} id="identificationRespondersList" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)}
                                    value={riskAssessment.identificationRespondersList}
                                    disabled={riskAssessment.status !== RiskAssessmentStatus.NEW || riskAssessment.allStaffIdentification || riskAssessment.allPermanentStaffIdentification}
                                    onChange={(event, value) => setRiskAssessment(assessment => ({ ...assessment, identificationRespondersList: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" >Identification Welcome Message:</Typography>
                                <RichTextEditor editorHtmlString={riskAssessment.identificationWelcomeMessage} onChange={(editorHtmlString) => setRiskAssessment(assessment => ({ ...assessment, identificationWelcomeMessage: editorHtmlString }))} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color="textSecondary" > Identification End Date: </Typography>
                                <TextField fullWidth required type="datetime-local" variant="outlined" size="small"
                                    value={formatDateyyyymmdd(riskAssessment.identificationEndDate) ?? ''} onChange={(event) => setRiskAssessment(assessment => ({ ...assessment, identificationEndDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={4} >
                                <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} name="allStaffIdentification" checked={riskAssessment.allStaffIdentification} onChange={(event, checked) => setRiskAssessment(assessment => ({ ...assessment, allStaffIdentification: checked }))} />} label="All Staff are Participants" disabled={riskAssessment.status !== RiskAssessmentStatus.NEW} />
                            </Grid>
                            <Grid item xs={4} >
                                <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} name="allPermanentStaffIdentification" checked={riskAssessment.allPermanentStaffIdentification} onChange={(event, checked) => setRiskAssessment(assessment => ({ ...assessment, allPermanentStaffIdentification: checked }))} />} label="All Permanent Staff are Participants" disabled={riskAssessment.status !== RiskAssessmentStatus.NEW} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} name="notifyIdentificationRespondersByEmail" checked={riskAssessment.notifyIdentificationRespondersByEmail} onChange={(event, checked) => setRiskAssessment(assessment => ({ ...assessment, notifyIdentificationRespondersByEmail: checked }))} />} label="Notify Identification Participants by Email" />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} ><br /><Typography color="secondary" > Risk Voting Setup </Typography> <Divider orientation="horizontal" color="red" style={{ borderBottomWidth: 1 }} /></Grid>
                    <Grid item xs={12} >
                        <Typography color="textSecondary" >Risk Voting Participants:</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape multiple={true} options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)}
                            value={riskAssessment.votingRespondersList}
                            onChange={(event, value) => setRiskAssessment(assessment => ({ ...assessment, votingRespondersList: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" >Voting End Date:</Typography>
                        <TextField fullWidth required type="datetime-local" variant="outlined" size="small"
                            value={formatDateyyyymmdd(riskAssessment.votingEndDate) ?? ''} onChange={(event) => setRiskAssessment(assessment => ({ ...assessment, votingEndDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textSecondary" >Min Severity For Voting:</Typography>
                        <TextField fullWidth select variant="outlined" size="small"
                            value={riskAssessment.minSeverityForVoting} onChange={event => setRiskAssessment(assessment => ({ ...assessment, minSeverityForVoting: Number(event.target.value) }))}>
                            <MenuItem key={4} value={4}>4 &gt; {SeverityDescription[4]}</MenuItem >
                            <MenuItem key={3} value={3}>3 &gt; {SeverityDescription[3]}</MenuItem >
                            <MenuItem key={2} value={2}>2 &gt; {SeverityDescription[2]}</MenuItem >
                            <MenuItem key={1} value={1}>1 &gt; {SeverityDescription[1]}</MenuItem >
                            <MenuItem key={0} value={0}>0 &gt; {SeverityDescription[0]}</MenuItem >
                        </TextField>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox classes={{ checked: "CheckBoxChecked" }} name="notifyVotingRespondersByEmail" checked={riskAssessment.notifyVotingRespondersByEmail} onChange={(event, checked) => setRiskAssessment(assessment => ({ ...assessment, notifyVotingRespondersByEmail: checked }))} />} label="Notify Voting Participants by Email" />
                    </Grid>
                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter" >
                <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button disabled={!riskAssessment.assessmentName || !riskAssessment.description} variant="contained" size="small" color="info" onClick={() => props.formEditMode === FormEditMode.CREATE ? handleCreateRiskAssessment() : handleUpdateRiskAssessment()} ><Save /> &nbsp;{props.formEditMode === FormEditMode.CREATE ? "Create Assessment" : "Update Assessment"} </Button>
                </Grid>
            </Box>
        </Paper>
    )
}
