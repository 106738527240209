import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { MainLayout } from "../layouts/MainLayout";
import { useAppSelector } from "../store/store";
import { Home } from "../views/Home";
import { LoginTepngUser } from "../views/LoginTepngUser";
import { RoutePathNames } from "../helpers/Constants";
import { RiskIdentificationEditor } from "../views/RiskIdentificationEditor";
import { RiskAssessmentEditor } from "../views/RiskAssessmentEditor";
import { RiskAssignmentEditor } from "../views/RiskAssignmentEditor";
import { RiskTreatmentEditor } from "../views/RiskTreatmentEditor";
import { RiskMonitoringEditor } from "../views/RiskMonitoringEditor";
import { ViewReports } from "../views/ViewReports";
import { ManageEntities } from "../views/ManageEntities";
import { ManageCategories } from "../views/ManageCategories";
import { ManageBusinessObjectives } from "../views/ManageBusinessObjectives";
import { ManageRatingDescriptions } from "../views/ManageRatingDescriptions";
import { ManageImpactAreas } from "../views/ManageImpactAreas";
import { ManageEmailTemplates } from "../views/ManageEmailTemplates";
import { VoteRiskAssessment } from "../views/VoteRiskAssessment";
import { ManageUserRoles } from "../views/ManageUserRoles";


interface ProtectedRouteProps {
  children: React.ReactNode
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
  const location = useLocation();

  if (loggedOnUserProfile == null) {
    localStorage.setItem("loginRedirectedFromUrl", location.pathname + location.search);
    return <><Navigate to={"/" + RoutePathNames.LOGIN} replace /></>
  }
  else
    return <>{children}</>
}

function NoRouteMatch() {
  const location = useLocation();
  return (<div> <h3> No Route match for <code>{location.pathname}</code> </h3> </div>);
}


export function RiskManRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><MainLayout header="Home Page" > <Home /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.HOMEPAGE} element={<ProtectedRoute><MainLayout header="Home Page" > <Home /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.LOGIN} element={<MainLayout header="" drawerHidden> <LoginTepngUser /> </MainLayout>} />
      <Route path={"/" + RoutePathNames.RISK_IDENTIFICATION} element={<ProtectedRoute><MainLayout header="Risk Identification" > <RiskIdentificationEditor /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.VOTE_RISKASSESSMENT} element={<ProtectedRoute><MainLayout header="Risk Assessment Voting" > <VoteRiskAssessment /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.RISK_ASSESSMENT} element={<ProtectedRoute><MainLayout header="Risk Assessment" > <RiskAssessmentEditor /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.RISK_ASSIGNMENT} element={<ProtectedRoute><MainLayout header="Risk Assignment" > <RiskAssignmentEditor /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.RISK_TREATMENT} element={<ProtectedRoute><MainLayout header="Risk Treatment" > <RiskTreatmentEditor /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.RISK_MONITORING} element={<ProtectedRoute><MainLayout header="Risk Monitoring" > <RiskMonitoringEditor /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.REPORTS} element={<ProtectedRoute><MainLayout header="Reports" > <ViewReports /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.ENTITIES} element={<ProtectedRoute><MainLayout header="Entities Setup" > <ManageEntities /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.CATEGORIES} element={<ProtectedRoute><MainLayout header="Categories Setup" > <ManageCategories /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.BUSINESS_OBJECTIVES} element={<ProtectedRoute><MainLayout header="Business Objectives Setup" > <ManageBusinessObjectives /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.IMPACTAREAS} element={<ProtectedRoute><MainLayout header="Impact Areas Setup" > <ManageImpactAreas /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.RATINGS} element={<ProtectedRoute><MainLayout header="Risk Ratings Setup" > <ManageRatingDescriptions /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.EMAIL_TEMPLATES} element={<ProtectedRoute><MainLayout header="Email Templates Setup" > <ManageEmailTemplates /> </MainLayout> </ProtectedRoute>} />
      <Route path={"/" + RoutePathNames.USERPROFILES} element={<ProtectedRoute><MainLayout header="User Profile Setup" > <ManageUserRoles /> </MainLayout> </ProtectedRoute>} />
      <Route path="*" element={<MainLayout header="" drawerHidden> <NoRouteMatch /> </MainLayout>} />
    </Routes>
  );
}