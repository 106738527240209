import { getRiskStatusString, getEntitiesListAsSpan, getUserFullName, formatShortDateString } from "../../helpers/Utils";
import { Button, Grid, Paper, TextField, Typography, Autocomplete, Box, MenuItem, } from "@mui/material";
import { Upload } from "@mui/icons-material";
import MaterialTable from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { RiskAssessment } from "../../helpers/Interfaces";
import { useAppSelector } from "../../store/store";
import { FormEditMode, RiskAssessmentDisplay, RiskAssessmentStatus } from "../../helpers/Constants";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { uploadAggregatedRisksFile } from "../../api-services/RiskAssessmentApi";

interface Props {
    riskAssessment: RiskAssessment
    onRiskAssessmentChange: (id: number, riskAssessment: RiskAssessment, formEditMode: FormEditMode, newRiskAssessmentAction: RiskAssessmentDisplay) => void
}

export function AggregateRiskAssessment({ riskAssessment, onRiskAssessmentChange }: Props) {
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);


    function handleUploadAggregatedRisksFile(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist(); //important to persist event so we can reset the value of the input file after an upload
        if (event.target.files == null || event.target.files.length === 0) return;
        uploadAggregatedRisksFile(riskAssessment, event.target.files[0]).then(result =>
            onRiskAssessmentChange(riskAssessment.id, result, FormEditMode.EDIT, RiskAssessmentDisplay.VIEW_RISKS_GRIDVIEW)
        )
        event.target.value = '' //important to persist event so we can reset the value of the input file after an upload
    }


    return (
        <Paper className="Form">
            <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails"> Identified Risks for {riskAssessment.assessmentName}</Typography></Box>
            <VerticalScrollableComponent viewPortHeightOffset={200}>
                <Grid id="RiskGridViewContainer" container style={{ padding: 5 }} >
                    <Grid item xs={12} style={{ overflowX: 'auto' }}>
                        {riskAssessment.risks && riskAssessment.risks.length > 0 &&
                            <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '100%' }} elevation={0} />, }}
                                columns={[
                                    { title: 'Delete Flag (x)', field: 'deleteFlag', hidden: true, export: true },
                                    { title: 'Submitted By', field: 'submittedBy', editable: 'never', render: data => getUserFullName(data.submittedBy), width: 200, export: false, },
                                    { title: 'Submitted Date', field: 'submittedDate', editable: 'never', render: data => formatShortDateString(data.submittedDate!), width: 120, export: false, },
                                    { title: 'Id', field: 'id', type: 'numeric', align: 'left', initialEditValue: 0, editable: 'never', width: 50 },
                                    { title: 'Status', field: 'status', editable: 'never', render: data => getRiskStatusString(data.status), width: 100, customExport: (row) => getRiskStatusString(row.status) },
                                    { title: 'Name', field: 'name', validate: rowData => rowData.name ? true : 'Name is required', width: 200 },
                                    { title: 'Description', field: 'description', initialEditValue: "", width: 400 },
                                    { title: 'Impact', field: 'impact', initialEditValue: 0, type: 'numeric', align: 'left', validate: rowData => isNaN(rowData.impact) ? 'Invalid Impact Value' : true, width: 100 },
                                    { title: 'Likelihood', field: 'likelihood', initialEditValue: 0, type: 'numeric', align: 'left', validate: rowData => isNaN(rowData.likelihood) ? 'Invalid Likelihood Value' : true, width: 100 },
                                    { title: 'Mitigation', field: 'mitigation', initialEditValue: 0, type: 'numeric', align: 'left', validate: rowData => isNaN(rowData.mitigation) ? 'Invalid Mitigation Value' : true, width: 100 },
                                    { title: 'Severity', field: 'severity', type: 'numeric', align: 'left',  width: 100, export: false },
                                    {
                                        title: 'Category', field: 'category', width: 200,
                                        editComponent: props => (<TextField style={{ width: 'max-content' }} name="categoryId" select variant="standard"
                                            value={props.value ?? ''} onChange={(e) => { props.onChange(Number(e.target.value)) }}>
                                            {riskRelatedTables.categories.filter(e => !e.disabled).map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)}
                                        </TextField>),
                                        render: data => data.category?.name,
                                        customExport: (data) => data.category?.name
                                    },
                                    {
                                        title: 'Business Objective Impacted', field: 'businessObjectiveImpacted', width: 200,
                                        editComponent: props => (<TextField style={{ width: 'max-content' }} name="businessObjectiveImpactedId" select variant="standard"
                                            value={props.value ?? ''} onChange={(e) => { props.onChange(Number(e.target.value)) }}>
                                            {riskRelatedTables.businessObjectives.filter(e => !e.disabled).map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)}
                                        </TextField>),
                                        render: data => data.businessObjectiveImpacted?.name,
                                        customExport: (data) => data.businessObjectiveImpacted?.name
                                    },
                                    {
                                        title: 'Entities Impacted', field: 'entitiesImpacted', type: 'string', width: 300,
                                        editComponent: props => (
                                            <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape multiple={true} id="entitiesImpactedIdsList" options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                                                value={props.rowData.entitiesImpacted}
                                                onChange={(event, value) => { props.onChange((value)) }} renderInput={(params) => (<TextField {...params} margin="normal" variant="standard" />)}
                                            />
                                        ),
                                        render: data =>
                                            <Typography color="textPrimary" style={{ fontSize: 12, fontFamily: "Arial" }} >
                                                {getEntitiesListAsSpan(data.entitiesImpacted, ' | ', 0)}
                                            </Typography>,
                                        customExport: (data) => data.entitiesImpacted.map(e => e.name).join(' | ')
                                    },
                                    {
                                        title: 'Entity Objectives Impacted', field: 'entityObjectivesImpacted', width: 300,
                                        editComponent: props => (<Autocomplete style={{ width: "150px" }} id="entityObjectivesImpacted" autoSelect freeSolo options={props.rowData.entitiesImpacted.map(row => row.objectives.split('\n')).flat()}
                                            value={props.value ?? ''} onChange={(e, value) => { props.onChange(value) }} renderInput={(params) => (<TextField {...params} margin="normal" variant="standard" />)}
                                        />)
                                    },
                                    { title: 'Frequency Of Mention', field: 'frequencyOfMention', type: 'numeric', align: 'left', initialEditValue: 0, editable: 'never', width: 50 },
                                    { title: 'Existing Mitigations', field: 'existingMitigations', hidden: true, export: true },
                                    { title: 'Suggested Mitigations', field: 'suggestedMitigations', hidden: true, export: true },
                                ]}
                                data={riskAssessment.risks}

                                localization={{ header: { actions: '' } }}

                                options={{
                                    pageSize: 30, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false, rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, actionsCellStyle: { color: '#0288D1' }, headerStyle: { color: '#0288D1' },
                                    exportAllData: true, tableLayout: "fixed",
                                    exportMenu: [
                                        { label: 'Export PDF', exportFunc: (cols, datas) => ExportPdf(cols, datas, riskAssessment.assessmentName + "_RisksForAggregation") },
                                        { label: 'Export CSV', exportFunc: (cols, datas) => ExportCsv(cols, datas, riskAssessment.assessmentName + "_RisksForAggregation") }
                                    ]
                                }}
                            />}
                    </Grid>
                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter">
                <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button variant="contained" size="small" color="secondary" component="label" disabled={riskAssessment.status !== RiskAssessmentStatus.AGGREGATION}><Upload /> &nbsp;Upload Aggregated Risks File
                        <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden type="file" onChange={(event) => handleUploadAggregatedRisksFile(event)} />
                    </Button></Grid>
            </Box>
        </Paper>
    )
}
