import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServerAppConfig } from '../helpers/Interfaces';

interface ServerAppConfigState {
  value: ServerAppConfig | null
}

export const serverAppConfig = createSlice({
  name: 'serverAppConfig',
  initialState: {
    value: null,
  } as ServerAppConfigState,
  reducers: {
    setServerAppConfig: (state, action: PayloadAction<ServerAppConfig | null>) => {
      state.value = action.payload
    },
  },
})

export const { setServerAppConfig } = serverAppConfig.actions;
export default serverAppConfig.reducer;