import { formatDateyyyymmdd, formatShortDateString, getRatingDescription, getUserFullName } from "../../helpers/Utils";
import { useEffect, useState } from "react";
import { Grid, Typography, Divider, Paper, Box, Button, TextField, Autocomplete, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Class } from "@mui/icons-material";
import { Risk } from "../../helpers/Interfaces";
import { assignRisk } from "../../api-services/RiskApi";
import { useAppSelector } from "../../store/store";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";


interface Props {
    riskToAssign: Risk
    onRiskChange: (id: number, risk: Risk) => void
}

export function AssignRisk(props: Props) {
    // state independent variables
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const cmdbUsersId = useAppSelector(state => state.cmdbUsersId.value);
    //states
    const [risk, setRisk] = useState<Risk>(props.riskToAssign)

    // #region effects
    useEffect(() => {
        setRisk(props.riskToAssign);
    }, [props.riskToAssign])
    // #endregion effects

    function handleAssignRisk() {
        assignRisk(risk).then(result => props.onRiskChange(result.id, result))
    }

    return (
        <Paper className="Form" >
            <Box className="FormHeader"><Typography className="FormHeaderOrFooterDetails"> Risk Assignment for {risk.name}</Typography></Box>
            <VerticalScrollableComponent viewPortHeightOffset={231}>
                <Grid id="AssignPriorityRemediationViewContainer" container spacing={4} className="FormDetails" >
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: '#cc0000' }}>*</span> Target Mitigation (Current Mitigation = {risk.mitigation})</Typography>
                        <Divider />
                        <RadioGroup name="targetMitigation" value={risk.targetMitigation} onChange={(event, value) => setRisk(risk => ({ ...risk, targetMitigation: event.target.value === 'null' ? null : Number(value) }))}>
                            <FormControlLabel style={{ color: "#000000" }} key={6} value={'null'} control={<Radio color="secondary" />} label='Not Selected' />
                            <FormControlLabel style={{ color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ alignSelf: "center" }} textAlign={"left"} color="textSecondary" >Target Response Date:</Typography>
                        <TextField fullWidth required type="datetime-local" variant="outlined" size="small"
                            value={formatDateyyyymmdd(risk.responseDate) ?? ''} onChange={(event) => setRisk(risk => ({ ...risk, responseDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ alignSelf: "center" }} textAlign={"left"} color="textSecondary" >Sponsor:</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="sponsor" options={riskRelatedTables.sponsorIds} getOptionLabel={(option) => getUserFullName(option, true)??''}
                            value={risk.sponsor} onChange={(event, value) => setRisk(risk => ({ ...risk, sponsor: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ alignSelf: "center" }} textAlign={"left"} color="textSecondary" >Risk Champion:</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="champion" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)??''}
                            value={risk.champion} onChange={(event, value) => setRisk(risk => ({ ...risk, champion: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ alignSelf: "center" }} textAlign={"left"} color="textSecondary" >Alternate Champion:</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="alternateChampion"  options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)??''}
                            value={risk.alternateChampion} onChange={(event, value) => setRisk(risk => ({ ...risk, alternateChampion: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ alignSelf: "center" }} textAlign={"left"} color="textSecondary" >Risk Champion Entity:</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="championEntity" options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                            value={risk.championEntity} isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, value) => setRisk(risk => ({ ...risk, championEntity: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                        />
                    </Grid>
                    {risk.approvalComments !== "" && <Grid item xs={12}>
                        <Typography color="textSecondary"  >Approval Comments</Typography>
                        <Divider />
                        <Typography color="textPrimary" title={risk.approvalComments}> {risk.approvalComments}</Typography>
                    </Grid>}
                    {risk.championNominationRejectedBy && risk.championNominationRejectedDate && <Grid item xs={12}>
                        <Typography color="textSecondary" >Champion Nomination Rejected By</Typography>
                        <Divider />
                        <Typography color="textPrimary" title={risk.championNominationRejectedBy}> {getUserFullName(risk.championNominationRejectedBy) + ' - ' + formatShortDateString(risk.championNominationRejectedDate)}: <span style={{ color: 'red' }}>{risk.championNominationRejectionComments}</span></Typography>
                    </Grid>}
                    <Grid item xs={12} >
                        {risk.targetMitigation != null && risk.responseDate != null && risk.sponsor != null && risk.champion != null && risk.championEntity != null &&
                            <Typography color="secondary" align="center" width="100%" fontSize={16}>
                                Clicking the Assign button below will assign this risk to the Specified Risk Champion and Sponsor.
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </VerticalScrollableComponent>
            <Box className="FormFooter">
                <Grid item xs={12} className="FormHeaderOrFooterDetails" alignItems='center'>
                    <Button onClick={() => handleAssignRisk()} variant="contained" size="medium" color="secondary" disabled={risk.targetMitigation == null || risk.responseDate == null || risk.sponsor == null || risk.championEntity == null}><Class /> &nbsp; Assign </Button>
                </Grid>
            </Box>
        </Paper >
    )
}
