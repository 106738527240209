import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CmdbUsersIdState {
  value: string[]
}

export const cmdbUsersId = createSlice({
  name: 'cmdbUsersId',
  initialState: {
    value: [],
  } as CmdbUsersIdState,
  reducers: {
    setCmdbUsersId: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload
    },
  },
})

export const { setCmdbUsersId } = cmdbUsersId.actions;
export default cmdbUsersId.reducer;