import { Email, Refresh, Sort, ArrowRight, TableView, ModeEditOutline, DeleteOutline, Send, MoreHoriz } from "@mui/icons-material";
import { Paper, Table, TableBody, TableRow, TableCell, ButtonGroup, Button, Menu, MenuItem, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, IconButton } from "@mui/material";
import { RiskStatus, RiskAssessmentStatus, RiskGroupFilters, RiskSortType, RiskIdentificationDisplay } from "../../helpers/Constants";
import { checkRiskCreateable, truncateTextToWhiteSpace, checkRiskEditable, checkRiskDeleteable, checkUserIsEntityCorrespondent, checkUserIsEntityHead } from "../../helpers/Utils";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { useAppSelector } from "../../store/store";
import { useState } from "react";
import { Risk, RiskAssessment } from "../../helpers/Interfaces";


interface Props {
    assessmentIdForRiskIdentification: number | null
    riskAssessmentForRiskIdentification: RiskAssessment | null
    riskGroupFilter: RiskGroupFilters
    risks: Risk[]
    selectedRisk: Risk | null | undefined
    onRiskGroupFilterChange: (riskGroupFilter: RiskGroupFilters) => void
    onRiskSortTypeChange: (riskSortType: RiskSortType) => void
    onClickDeleteRisk: (riskId: number) => void
    onClickNotifyEntityHead: (riskId: number) => void
    onClickSubmitAllRisks: () => void
    onClickNotifyEntityHeadAllEntityRisks: () => void
    onClickRefreshRisks: () => void
    onChangeRiskIdentificationDisplay: (display: RiskIdentificationDisplay, risk?: Risk | null) => void
}

export function RiskIdentificationWidget(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    //states
    const [showSortMenu, setShowSortMenu] = useState(false)
    const [riskActionMenuAnchorEl, setRiskActionMenuAnchorEl] = useState<Element | null>(null)
    const [showRiskActionMenu, setShowRiskActionMenu] = useState(false)
    const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<Element | null>(null)
    //state dependent variables
    const isAnyEntityCorrespondent = loggedOnUserProfile == null ? false : checkUserIsEntityCorrespondent(riskRelatedTables.entities, loggedOnUserProfile)
    const isAnyEntityHead = loggedOnUserProfile == null ? false : checkUserIsEntityHead(riskRelatedTables.entities, loggedOnUserProfile)

    return (loggedOnUserProfile && props.risks &&
        <Paper className="Widget">
            <Table style={{ width: '288px' }}>
                <TableBody>
                    <TableRow >
                        <TableCell align='center' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 10 }}>
                            <ButtonGroup size="small" variant="text" color="info"   >
                                <Button color="secondary" onClick={() => props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.CREATE_RISK)} title="Add new Risk" disabled={!checkRiskCreateable(props.riskAssessmentForRiskIdentification, loggedOnUserProfile)}>New Risk </Button>
                                <Button color='info' onClick={() => props.onClickSubmitAllRisks()} title="Submit All Risks" disabled={props.risks.find(r => r.status === RiskStatus.IDENTIFIED) == null || (props.riskAssessmentForRiskIdentification != null && (props.riskAssessmentForRiskIdentification.status !== RiskAssessmentStatus.IDENTIFICATION || props.riskAssessmentForRiskIdentification.identificationEndDate == null || new Date(props.riskAssessmentForRiskIdentification.identificationEndDate) < new Date()))}>Submit All</Button>
                                <Button size="small" color="info" onClick={() => props.onClickNotifyEntityHeadAllEntityRisks()} title="Notify Entity Head for all Entity Risks identified" disabled={props.riskGroupFilter !== RiskGroupFilters.ENTITY_RISKS || props.risks.find(r => r.isEntityRisk && r.status === RiskStatus.IDENTIFIED) == null || !isAnyEntityCorrespondent}><Email /></Button>
                                <Button color='info' onClick={() => props.onClickRefreshRisks()} title="Refresh Risks"><Refresh /> </Button>
                                <Button color='info' onClick={(event) => { setSortMenuAnchorEl(event.currentTarget); setShowSortMenu(true) }} title="Sort Risks By"><Sort /> </Button>
                            </ButtonGroup>
                            <Menu anchorEl={sortMenuAnchorEl} open={showSortMenu} onClose={() => setShowSortMenu(false)} >
                                <MenuItem id="sortDateCreatedDesc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortDateCreatedDesc) }}>Date Created &#8595;</MenuItem>
                                <MenuItem id="sortDateCreatedAsc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortDateCreatedAsc) }}>Date Created &#8593;</MenuItem>
                                <MenuItem id="sortNameDesc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortNameDesc) }}>Name &#8595;</MenuItem>
                                <MenuItem id="sortNameAsc" onClick={() => { setShowSortMenu(false); props.onRiskSortTypeChange(RiskSortType.SortNameAsc) }}>Name &#8593;</MenuItem>
                            </Menu>
                        </TableCell >
                    </TableRow>
                    <TableRow>
                        <TableCell align='left' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
                            <RadioGroup row name="riskGroupFilter" value={props.riskGroupFilter} onChange={(event, value) => props.onRiskGroupFilterChange(Number(value))}>
                                <FormControlLabel style={{ color: "#000000" }} key={RiskGroupFilters.MY_RISKS} value={RiskGroupFilters.MY_RISKS} control={<Radio color="secondary" />} label="My Risks" />
                                <FormControlLabel style={{ color: "#000000" }} key={RiskGroupFilters.ENTITY_RISKS} value={RiskGroupFilters.ENTITY_RISKS} control={<Radio color="secondary" />} label="My Entity Risks" disabled={!(isAnyEntityCorrespondent || isAnyEntityHead)} />
                                {loggedOnUserProfile.isERMTeamMember && <FormControlLabel style={{ color: "#000000" }} key={RiskGroupFilters.GLOBAL_RISKS} value={RiskGroupFilters.GLOBAL_RISKS} control={<Radio color="secondary" />} label="Global Risks" />}
                                {isAnyEntityHead && <FormControlLabel style={{ color: "#000000" }} key={RiskGroupFilters.ENTITY_RISKS_NOT_SUBMITTED} value={RiskGroupFilters.ENTITY_RISKS_NOT_SUBMITTED} control={<Radio color="secondary" />} label="Awaiting Action" />}
                            </RadioGroup>
                        </TableCell >
                    </TableRow>
                    <TableRow >
                        <TableCell style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                            <VerticalScrollableComponent viewPortHeightOffset={props.assessmentIdForRiskIdentification == null ? 310 : 310 - 32}>
                                <List style={{ padding: 0 }}>
                                    {props.risks.map(risk =>
                                        <ListItem divider={false} key={risk.id} classes={{ selected: "SelectedMenu", root: "Menu" }} selected={props.selectedRisk?.id === risk.id}>
                                            <ArrowRight fontSize="medium" color={props.selectedRisk?.id === risk.id ? "secondary" : "primary"} />
                                            <ListItemText style={{ wordWrap: 'break-word', cursor: 'pointer', width: '100%', color: risk.status === RiskStatus.IDENTIFIED ? "#0288D1" : "#000000" }} onClick={() => props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.PREVIEW_RISK, risk)} primary={truncateTextToWhiteSpace(risk.name, 60)} title={risk.name} />
                                            <IconButton color={props.selectedRisk?.id === risk.id ? "secondary" : "primary"} edge="end" onClick={(event) => { props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.PREVIEW_RISK, risk); setRiskActionMenuAnchorEl(event.currentTarget); setShowRiskActionMenu(true) }} title="select an action for the Risk">
                                                <MoreHoriz />
                                            </IconButton>
                                        </ListItem>
                                    )}
                                </List>
                            </VerticalScrollableComponent>
                            {props.selectedRisk &&
                                <Menu anchorEl={riskActionMenuAnchorEl} open={showRiskActionMenu} onClose={() => setShowRiskActionMenu(false)} >
                                    <MenuItem style={{ color: '#333333' }} onClick={() => { setShowRiskActionMenu(false); props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.VIEW_RISK, props.selectedRisk) }}>
                                        <TableView /> &nbsp; Risk Details
                                    </MenuItem>
                                    <MenuItem style={{ color: '#333333' }} onClick={() => { setShowRiskActionMenu(false); props.onChangeRiskIdentificationDisplay(RiskIdentificationDisplay.EDIT_RISK, props.selectedRisk) }} disabled={!checkRiskEditable(props.selectedRisk, props.riskAssessmentForRiskIdentification, loggedOnUserProfile)}>
                                        <ModeEditOutline />&nbsp; Edit Risk
                                    </MenuItem>
                                    <MenuItem style={{ color: '#333333' }} onClick={() => { setShowRiskActionMenu(false); props.onClickDeleteRisk(props.selectedRisk!.id) }} disabled={!checkRiskDeleteable(props.selectedRisk, props.riskAssessmentForRiskIdentification, loggedOnUserProfile)}>
                                        <DeleteOutline />&nbsp; Delete Risk
                                    </MenuItem>
                                    <MenuItem style={{ color: '#333333' }} onClick={() => { setShowRiskActionMenu(false); props.onClickNotifyEntityHead(props.selectedRisk!.id) }} disabled={!props.selectedRisk.isEntityRisk || props.selectedRisk.status !== RiskStatus.IDENTIFIED || !checkUserIsEntityCorrespondent(props.selectedRisk.entitiesImpacted, loggedOnUserProfile)}>
                                        <Send />&nbsp; Notify Entity Head
                                    </MenuItem>
                                </Menu>
                            }
                        </TableCell >
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
}
