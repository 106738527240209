import { Send } from "@mui/icons-material";
import { Paper, Box, Typography, Grid, Divider, TextField, RadioGroup, FormControlLabel, Radio, Autocomplete, Stack, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ImpactLikelihoodColor, MitigationColor } from "../../helpers/Constants";
import { Risk, RiskChange } from "../../helpers/Interfaces";
import { getEntitiesListAsSpan, getRatingDescription, getUserFullName, formatLongDateString, checkSubmitRiskChangeValidation, showConfirmBox, formatDateyyyymmdd, getUserEmail } from "../../helpers/Utils";
import { useAppSelector } from "../../store/store";
import { submitRiskChange } from "../../api-services/RiskApi";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";


interface Props {
    riskToEdit: Risk
    onRiskChange: (id: number, risk: Risk) => void
}

export function CreateValidateRiskChange(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const cmdbUsersId = useAppSelector(state => state.cmdbUsersId.value);
    const emptyRiskChange: RiskChange = {
        id: 0, riskId: 0, name: "", description: "", impact: 0, likelihood: 0, mitigation: 0, existingMitigations: "", suggestedMitigations: "", changeCreatedBy: loggedOnUserProfile?.email ?? "", changeCreatedDate: new Date(),
        category: null, businessObjectiveImpacted: null, entitiesImpacted: [], entityObjectivesImpacted: "", severity: 0, prioritisation: 0,
        championEntity: null, champion: null, alternateChampion: null, sponsor: null, targetMitigation: null, responseDate: null, changeJustification: ""
    }
    //states
    const [riskChange, setRiskChange] = useState<RiskChange>(emptyRiskChange)


    // #region effects
    useEffect(() => {
        const initialRiskChange: RiskChange = {
            id: 0, riskId: props.riskToEdit.id, name: props.riskToEdit.name, description: props.riskToEdit.description,
            impact: props.riskToEdit.impact, likelihood: props.riskToEdit.likelihood, mitigation: props.riskToEdit.mitigation,
            severity: props.riskToEdit.severity, prioritisation: props.riskToEdit.prioritisation,
            existingMitigations: props.riskToEdit.existingMitigations, suggestedMitigations: props.riskToEdit.suggestedMitigations, category: props.riskToEdit.category,
            businessObjectiveImpacted: props.riskToEdit.businessObjectiveImpacted, entitiesImpacted: props.riskToEdit.entitiesImpacted,
            entityObjectivesImpacted: props.riskToEdit.entityObjectivesImpacted,
            championEntity: props.riskToEdit.championEntity, champion: props.riskToEdit.champion, alternateChampion: props.riskToEdit.alternateChampion, sponsor: props.riskToEdit.sponsor,
            targetMitigation: props.riskToEdit.targetMitigation, responseDate: props.riskToEdit.responseDate,
            changeCreatedDate: new Date(), changeCreatedBy: "", changeJustification: ""
        }
        setRiskChange(initialRiskChange);
    }, [props.riskToEdit])
    // #endregion effects


    function handleConfirmSubmitRiskChange() {
        if (riskChange == null) return
        showConfirmBox("You will not be able to make changes after submitting. Are you sure you want to Submit this RiskChange?", "Confirm Submit Risk Change")
            .then((result) => {
                if (result.isConfirmed)
                    submitRiskChange(props.riskToEdit, riskChange).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }


    return (
        riskChange && loggedOnUserProfile &&
        <Paper className="Form" >
            <Box className="FormHeader" >
                <Typography className="FormHeaderOrFooterDetails" > Submit Risk Change </Typography>
            </Box>
            <VerticalScrollableComponent viewPortHeightOffset={231}>
                <Grid id="CreateEditRiskFormDetails" container spacing={3} className="FormDetails">
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Change Justification </Typography>
                        <TextField fullWidth required name="Change Justification" type="text" variant="outlined" multiline size="small" minRows={3}
                            value={riskChange.changeJustification} onChange={event => setRiskChange(riskChange => ({ ...riskChange, changeJustification: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Risk Name </Typography>
                        <Typography color={props.riskToEdit.name === riskChange.name ? "textPrimary" : 'red'} >{props.riskToEdit.name} </Typography>
                        <TextField fullWidth required name="name" type="text" variant="outlined" size="small"
                            value={riskChange.name} onChange={event => setRiskChange(riskChange => ({ ...riskChange, name: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Description </Typography>
                        <Typography color={props.riskToEdit.description === riskChange.description ? "textPrimary" : 'red'}  >{props.riskToEdit.description} </Typography>
                        <TextField fullWidth required name="description" type="text" variant="outlined" multiline size="small" minRows={3}
                            value={riskChange.description} onChange={event => setRiskChange(riskChange => ({ ...riskChange, description: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Strategic Objective Impacted</Typography>
                        <Typography color={props.riskToEdit.businessObjectiveImpacted?.id === riskChange.businessObjectiveImpacted?.id ? "textPrimary" : 'red'}  >{props.riskToEdit.businessObjectiveImpacted?.name}</Typography>
                        <Divider />
                        <RadioGroup name="businessObjectiveImpacted" value={riskChange.businessObjectiveImpacted?.id ?? ''} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, businessObjectiveImpacted: riskRelatedTables.businessObjectives.find(e => e.id === Number(value)) }))}>
                            {riskRelatedTables.businessObjectives.filter(e => !e.disabled).map(row => <FormControlLabel style={{ color: "#000000" }} key={row.id} value={row.id} control={<Radio color="secondary" />} label={row.name} />)}
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Category (select one)</Typography>
                        <Typography color={props.riskToEdit.category?.id === riskChange.category?.id ? "textPrimary" : 'red'}  >{props.riskToEdit.category?.name} </Typography>
                        <Divider />
                        <RadioGroup name="category" value={riskChange.category?.id ?? ''} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, category: riskRelatedTables.categories.find(e => e.id === Number(value)) }))}>
                            {riskRelatedTables.categories.filter(e => !e.disabled).map(row => <FormControlLabel style={{ padding: 5, color: "#000000" }} key={row.id} value={row.id} control={<Radio color="secondary" />} label={row.name + ' - ' + row.description} />)}
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Entities Impacted {props.riskToEdit.isEntityRisk ? '(This is an Entity Risk Identified by Correspondent)' : ''}</Typography>
                        <Typography color={props.riskToEdit.entitiesImpacted.length === riskChange.entitiesImpacted.length && props.riskToEdit.entitiesImpacted.every(el => riskChange.entitiesImpacted.includes(el)) ? "textPrimary" : 'red'}  >
                            {props.riskToEdit.entitiesImpacted?.length > 0 && getEntitiesListAsSpan(props.riskToEdit.entitiesImpacted, ',', 4)}
                        </Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape multiple={true} id="entitiesImpactedIdsList" options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={riskChange.entitiesImpacted} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, entitiesImpacted: value }))}
                            renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                        />
                    </Grid>
                    {props.riskToEdit.entityObjectivesImpacted && <Grid item xs={12}>
                        <Typography color="textSecondary" >Entity Objectives Impacted</Typography>
                        <Typography color={props.riskToEdit.entityObjectivesImpacted === riskChange.entityObjectivesImpacted ? "textPrimary" : 'red'} >{props.riskToEdit.entityObjectivesImpacted} </Typography>
                        <Autocomplete fullWidth id="entityObjectiveImpacted" autoSelect freeSolo size="small" options={riskChange.entitiesImpacted.map(row => row.objectives ? row.objectives.split('\n') : []).flat()}
                            value={riskChange.entityObjectivesImpacted ?? ''}
                            onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, entityObjectiveImpacted: value ?? "" }))}
                            renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                        />
                    </Grid>}
                    <Grid item xs={12} >
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Impact </Typography>
                        <Stack direction="row" spacing={2} alignItems='center'>
                            <Typography align="center" fontSize={16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[props.riskToEdit.impact] }}>{props.riskToEdit.impact}</Typography>
                            <Typography style={{ padding: 5 }} color={props.riskToEdit.impact === riskChange.impact ? "textPrimary" : 'red'} >
                                {getRatingDescription(props.riskToEdit.impact, riskRelatedTables.ratingDescriptions, "IMPACT")}
                            </Typography>
                        </Stack>
                        <Divider />
                        <RadioGroup name="impact" value={riskChange.impact ?? ''} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, impact: Number(value) }))}>
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "IMPACT")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "IMPACT")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "IMPACT")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "IMPACT")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "IMPACT")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "IMPACT")} />
                        </RadioGroup>
                    </Grid >
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Likelihood </Typography>
                        <Stack direction="row" spacing={2} alignItems='center'>
                            <Typography align="center" fontSize={16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: ImpactLikelihoodColor[props.riskToEdit.likelihood] }}>{props.riskToEdit.likelihood}</Typography>
                            <Typography style={{ padding: 5 }} color={props.riskToEdit.likelihood === riskChange.likelihood ? "textPrimary" : 'red'}>
                                {getRatingDescription(props.riskToEdit.likelihood, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")}
                            </Typography>
                        </Stack>
                        <Divider />
                        <RadioGroup name="likelihood" value={riskChange.likelihood} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, likelihood: Number(value) }))}>
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "LIKELIHOOD")} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>*</span> Mitigation </Typography>
                        <Stack direction="row" spacing={2} alignItems='center'>
                            <Typography align="center" fontSize={16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 30, width: 30, height: 30, color: '#FFFFFF', backgroundColor: MitigationColor[props.riskToEdit.mitigation] }}>{props.riskToEdit.mitigation}</Typography>
                            <Typography style={{ padding: 5 }} color={props.riskToEdit.mitigation === riskChange.mitigation ? "textPrimary" : 'red'} >
                                {getRatingDescription(props.riskToEdit.mitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}
                            </Typography>
                        </Stack>
                        <Divider />
                        <RadioGroup name="mitigation" value={riskChange.mitigation} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, mitigation: Number(value) }))}>
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel style={{ padding: 3, color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" ><span style={{ color: 'red' }}>{riskChange.mitigation > 1 ? '*' : ''}</span> Existing Mitigations </Typography>
                        <Typography color={props.riskToEdit.existingMitigations === riskChange.existingMitigations ? "textPrimary" : 'red'}>{props.riskToEdit.existingMitigations} </Typography>
                        <TextField fullWidth required name="existingMitigations" type="text" variant="outlined" multiline size="small" minRows={3}
                            value={riskChange.existingMitigations}
                            onChange={(event) => setRiskChange(riskChange => ({ ...riskChange, existingMitigations: event.target.value }))} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary" >Suggested Mitigations</Typography>
                        <Typography color={props.riskToEdit.suggestedMitigations === riskChange.suggestedMitigations ? "textPrimary" : 'red'} >{props.riskToEdit.suggestedMitigations} </Typography>
                        <TextField fullWidth required name="suggestedMitigations" type="text" variant="outlined" multiline size="small" minRows={3}
                            value={riskChange.suggestedMitigations}
                            onChange={(event) => setRiskChange(riskChange => ({ ...riskChange, suggestedMitigations: event.target.value }))} />
                    </Grid>
                    {props.riskToEdit.championEntity && <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Risk Champion Entity</Typography>
                        <Typography color={props.riskToEdit.championEntity === riskChange.championEntity ? "textPrimary" : 'red'} title={props.riskToEdit.championEntity?.baseRadicalShort}> {props.riskToEdit.championEntity?.name}</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="championEntity" options={riskRelatedTables.entities.filter(e => !e.disabled)} getOptionLabel={(row) => row.name}
                            value={riskChange.championEntity} isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, championEntity: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" />)}
                        />
                    </Grid>}
                    {props.riskToEdit.sponsor && <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Risk Sponsor</Typography>
                        <Typography color={props.riskToEdit.sponsor === riskChange.sponsor ? "textPrimary" : 'red'} title={getUserEmail(props.riskToEdit.sponsor)}> {getUserFullName(props.riskToEdit.sponsor)}</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="sponsor" options={riskRelatedTables.sponsorIds} getOptionLabel={(option) => getUserFullName(option, true)??''}
                            value={riskChange.sponsor} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, sponsor: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>}
                    {props.riskToEdit.champion && <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Risk Champion</Typography>
                        <Typography color={props.riskToEdit.champion === riskChange.champion ? "textPrimary" : 'red'} title={getUserEmail(props.riskToEdit.champion)}> {getUserFullName(props.riskToEdit.champion)}</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="champion"  options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)??''}
                            value={riskChange.champion} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, champion: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>}
                    {props.riskToEdit.alternateChampion && <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Alternate Champion</Typography>
                        <Typography color={props.riskToEdit.alternateChampion === riskChange.alternateChampion ? "textPrimary" : 'red'} title={getUserEmail(props.riskToEdit.alternateChampion)}> {getUserFullName(props.riskToEdit.alternateChampion)}</Typography>
                        <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="alternateChampion" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)??''}
                            value={riskChange.alternateChampion} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, alternateChampion: value }))} renderInput={(params) => (<TextField {...params} variant="outlined" />)} />
                    </Grid>}
                    {props.riskToEdit.responseDate && <Grid item xs={12} sm={6}>
                        <Typography color="textSecondary" >Target Risk Closure Date</Typography>
                        <Typography color={props.riskToEdit.responseDate === riskChange.responseDate ? "textPrimary" : 'red'}> {formatLongDateString(props.riskToEdit.responseDate, true)}</Typography>
                        <TextField fullWidth required type="datetime-local" variant="outlined" size="small"
                            value={formatDateyyyymmdd(riskChange.responseDate) ?? ''}
                            onChange={(event) => setRiskChange(riskChange => ({ ...riskChange, responseDate: event.target.value === '' ? null : new Date(event.target.value) }))} />
                    </Grid>}
                    {props.riskToEdit.targetMitigation && <Grid item xs={12}>
                        <Typography color="textSecondary" >Target Mitigation</Typography>
                        <Stack direction="row" spacing={2} alignItems='center'>
                            <Typography align="center" fontSize={16} style={{ padding: 5, width: '30px', color: '#FFFFFF', backgroundColor: MitigationColor[props.riskToEdit.targetMitigation] }}>{props.riskToEdit.targetMitigation}</Typography>
                            <Typography style={{ padding: 5 }} color={props.riskToEdit.targetMitigation === riskChange.targetMitigation ? "textPrimary" : 'red'}>
                                {getRatingDescription(props.riskToEdit.targetMitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}
                            </Typography>
                        </Stack>
                        <RadioGroup name="targetMitigation" value={riskChange.targetMitigation} onChange={(event, value) => setRiskChange(riskChange => ({ ...riskChange, targetMitigation: Number(value) }))}>
                            <FormControlLabel disabled={props.riskToEdit.mitigation === 6} style={{ padding: 3, color: "#000000" }} key={6} value={6} control={<Radio color="secondary" />} label={"6 - " + getRatingDescription(6, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel disabled={props.riskToEdit.mitigation === 5} style={{ padding: 3, color: "#000000" }} key={5} value={5} control={<Radio color="secondary" />} label={"5 - " + getRatingDescription(5, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel disabled={props.riskToEdit.mitigation === 4} style={{ padding: 3, color: "#000000" }} key={4} value={4} control={<Radio color="secondary" />} label={"4 - " + getRatingDescription(4, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel disabled={props.riskToEdit.mitigation === 3} style={{ padding: 3, color: "#000000" }} key={3} value={3} control={<Radio color="secondary" />} label={"3 - " + getRatingDescription(3, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel disabled={props.riskToEdit.mitigation === 2} style={{ padding: 3, color: "#000000" }} key={2} value={2} control={<Radio color="secondary" />} label={"2 - " + getRatingDescription(2, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                            <FormControlLabel disabled={props.riskToEdit.mitigation === 1} style={{ padding: 3, color: "#000000" }} key={1} value={1} control={<Radio color="secondary" />} label={"1 - " + getRatingDescription(1, riskRelatedTables.ratingDescriptions, "MITIGATION")} />
                        </RadioGroup>
                    </Grid>}
                </Grid >
            </VerticalScrollableComponent>
            <Box className="FormFooter" >
                <Grid item xs={12} className="FormHeaderOrFooterDetails">
                    <Button disabled={!checkSubmitRiskChangeValidation(riskChange)} variant="contained" size="small" color="secondary" onClick={() => handleConfirmSubmitRiskChange()} ><Send /> &nbsp; Submit Risk Change </Button>
                </Grid>
            </Box>
        </Paper >
    )
}
