import MaterialTable from "@material-table/core";
import { Box, Paper, Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { createEntity, deleteEntity, getAllEntities, updateEntity } from "../api-services/EntityApi";
import { useAppSelector } from "../store/store";
import { Entity } from "../helpers/Interfaces";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { infoColor } from "../layouts/theme";
import { getUserFullName } from "../helpers/Utils";

export function ManageEntities() {
    // state independent variables
    const cmdbUsersId = useAppSelector(state => state.cmdbUsersId.value);
    //state
    const [entities, setEntities] = useState<Entity[]>([])

    useEffect(() => {
        getAllEntities().then(entities => setEntities(entities))
    }, [])


    function addRecord(newData: Entity) {
        return new Promise<void>((resolve, reject) => {
            createEntity(newData).then(result => {
                setEntities(entities => [...entities, result])
                resolve()
            })
        })
    }

    function updateRecord(newData: Entity, oldData: Entity | undefined) {
        return new Promise<void>((resolve, reject) => {
            updateEntity(newData).then(result => {
                setEntities(entities => entities.map(inst => inst.id === newData.id ? result : inst))
                resolve()
            })
        })
    }

    function deleteRecord(oldData: Entity) {
        return new Promise<void>((resolve, reject) => {
            deleteEntity(oldData.id).then(disabled => {
                if (disabled)
                    setEntities(entities => entities.map(inst => inst.id === oldData.id ? { ...oldData, disabled: true } : inst))
                else
                    setEntities(entities => entities.filter(inst => inst.id !== oldData.id))
                resolve()
            })
        })
    }


    return (
        <Box className='HorizontalOverFlowContainer'>
            <Paper className="Form">
                <br />
                <VerticalScrollableComponent viewPortHeightOffset={162}>
                    <Grid id="EntitiesViewContainer" container style={{ padding: 5 }} >
                        <Grid item xs={12}>
                            {entities && entities.length > 0 &&
                                <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: 2000 }} elevation={0} />, }}
                                    columns={[
                                        //{ title: 'Id', field: 'id', type: 'numeric', align: 'left', initialEditValue: 0, editable: 'never', width: 30 },
                                        { title: '*Entity Name', field: 'name', align: 'left', validate: rowData => rowData.name ? true : 'Name is required', width: 150 },
                                        { title: '*Radical Short', field: 'baseRadicalShort', align: 'left', validate: rowData => rowData.baseRadicalShort ? true : 'Radical Short is required', width: 180 },
                                        { title: 'Disabled', field: 'disabled', type: 'boolean', align: 'left', initialEditValue: false, width: 30 },
                                        {
                                            title: '*Director', field: 'director', type: 'string', width: 250,
                                            editComponent: props =>
                                                <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="director" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true) ?? ''}
                                                    value={props.value}
                                                    onChange={(event, value) => { props.onChange(value) }} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" style={{ overflowWrap: 'anywhere' }} />)}
                                                />,
                                            render: data => <Typography style={{ fontSize: 13, fontFamily: "Arial" }} color="textPrimary">{getUserFullName(data.director)}</Typography>
                                        },
                                        {
                                            title: '*Correspondents', field: 'correspondentsList', type: 'string', width: 200,
                                            editComponent: props =>
                                                <Autocomplete multiple ChipProps={{ color: "info" }} size="small" clearOnEscape id="correspondentsList" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true) ?? ''}
                                                    value={props.rowData.correspondentsList}
                                                    onChange={(event, value) => props.onChange(value)} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" style={{ overflowWrap: 'anywhere' }} />)}
                                                />,
                                            render: data =>
                                                <Typography color="textPrimary" style={{ fontSize: 13, fontFamily: "Arial" }} >
                                                    {data.correspondentsList.map(e => <><span>{getUserFullName(e,true)}</span><br /></>)}
                                                </Typography>
                                        },
                                        {
                                            title: '*Entity Heads', field: 'entityHeadsList', type: 'string', width: 200,
                                            editComponent: props =>
                                                <Autocomplete multiple ChipProps={{ color: "info" }} size="small" clearOnEscape id="entityHeadsList" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true) ?? ''}
                                                    value={props.rowData.entityHeadsList}
                                                    onChange={(event, value) => { props.onChange(value) }} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" style={{ overflowWrap: 'anywhere' }} />)}
                                                />,
                                            render: data =>
                                                <Typography color="textPrimary" style={{ fontSize: 13, fontFamily: "Arial" }} >
                                                    {data.entityHeadsList.map(e => <><span>{getUserFullName(e,true)}</span><br /></>)}
                                                </Typography>
                                        },
                                        {
                                            title: 'Objectives', align: 'left', field: 'objectives', type: 'string',
                                            editComponent: props =>
                                                <TextField style={{ fontSize: 11 }} fullWidth required id="objectives" type="text" variant="outlined" multiline size="small" minRows={3}
                                                    value={props.value} onChange={(event) => { props.onChange(event.target.value) }} />,
                                            render: data => <Typography color="textPrimary" style={{ fontSize: 12, fontFamily: "Arial", whiteSpace: 'pre-line' }} >{data.objectives} </Typography>
                                        },
                                    ]}
                                    data={entities}
                                    editable={{
                                        onRowAdd: (newData) => addRecord(newData),
                                        onRowUpdate: (newData, oldData) => updateRecord(newData, oldData),
                                        onRowDelete: (oldData) => deleteRecord(oldData),
                                    }}

                                    localization={{ header: { actions: '' } }}

                                    options={{
                                        toolbarButtonAlignment: "left", searchFieldAlignment: 'left', paginationAlignment: "flex-start",
                                        pageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false, rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, actionsCellStyle: { width: '50px', color: infoColor }, headerStyle: { color: infoColor },
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </VerticalScrollableComponent>
                <br />

            </Paper>
        </Box>
    )
}
