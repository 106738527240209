import { Typography, Toolbar, AppBar } from "@mui/material";
import '../App.css'
import { useAppSelector } from "../store/store";

interface AppBarFooterProps {
    appBarHeight: number
}
export function AppBarFooter({ appBarHeight }: AppBarFooterProps) {
    const serverAppConfig = useAppSelector(state => state.serverAppConfig.value);
    const appConfig = useAppSelector(state => state.appConfig.value);

    const appBar: React.CSSProperties = {
        height: appBarHeight,
        padding: 5,
        top: 'auto',
        bottom: 0,
        backgroundColor: "#FFFFFF",
        zIndex: 1300
    }


    return (
        <AppBar style={appBar} >
            <Toolbar classes={{ regular: "ToolbarRegular" }}>
                <img style={{ height: '40px' }} src='/total_logo.png' alt="total_logo.png" /> &nbsp;&nbsp;

                <Typography variant="subtitle1" color="textSecondary" >{serverAppConfig?.companyName}</Typography>  <div style={{ flexGrow: 1 }} />
                <Typography variant="caption" color="textPrimary">Product Development Contacts: </Typography>&nbsp;&nbsp;
                <Typography variant="caption" color="textPrimary">{appConfig.SUPPORT_CONTACTS}  </Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography variant="caption" color="textSecondary"
                    title={
                        "Client Environment: " + appConfig.ENVIRONMENT +
                        "\nServer Environment: " + serverAppConfig?.environment +
                        "\nClient App Version: " + appConfig.APP_VERSION +
                        "\nServer App Version: " + serverAppConfig?.appVersion +
                        "\nClient Last Updated: " + new Date(appConfig.APP_LAST_UPDATED) +
                        "\nServer Last Updated: " + (serverAppConfig == null ? "" : new Date(serverAppConfig.appLastUpdated))}>
                    &copy; 2022. All rights reserved.  &nbsp;&nbsp;&nbsp;{'(' + appConfig.ENVIRONMENT + ')'}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}
