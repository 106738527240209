import MaterialTable from "@material-table/core";
import { Box, Paper, Grid, Autocomplete, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { UserRole } from "../helpers/Interfaces";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { infoColor } from "../layouts/theme";
import { getAllUserRoles, addUserToRole, removeUserFromRole } from "../api-services/UserProfileApi";
import { useAppSelector } from "../store/store";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { GlobalRole } from "../helpers/Constants";
import { getUserFullName } from "../helpers/Utils";


export function ManageUserRoles() {
    // state independent variables
    const cmdbUsersId = useAppSelector(state => state.cmdbUsersId.value);
    //state
    const [userRoles, setUserRoles] = useState<UserRole[]>([])

    useEffect(() => {
        getAllUserRoles().then(e => setUserRoles(e))
    }, [])


    function addRecord(newData: UserRole) {
        return new Promise<void>((resolve, reject) => {
            addUserToRole(newData)
            .then(() => {
                setUserRoles(e => [...e, newData])
                resolve()
            })
            .catch(() => resolve())
        })
    }


    function deleteRecord(oldData: UserRole) {
        return new Promise<void>((resolve, reject) => {
            removeUserFromRole(oldData)
            .then(() => {
                setUserRoles(e => e.filter(inst => inst.userPrincipalId !== oldData.userPrincipalId || inst.role.toLowerCase() !== oldData.role.toLowerCase()))
                resolve()
            })
            .catch(() => resolve())
        })
    }


    return (
        <Box className='HorizontalOverFlowContainer'>
            <Paper className="Form">
                <br />
                <VerticalScrollableComponent viewPortHeightOffset={162}>
                    <Grid id="EntitiesViewContainer" container style={{ padding: 5 }} >
                        <Grid item xs={12}>
                            {
                                <MaterialTable components={{ Container: props => <Paper {...props} style={{ width: '100%' }} elevation={0} />, }}
                                    columns={[
                                        {
                                            title: 'User', field: 'userPrincipalId', align: 'left', validate: rowData => rowData.userPrincipalId ? true : 'User is required',
                                            editComponent: props =>
                                                <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="email" options={cmdbUsersId} getOptionLabel={(option) => getUserFullName(option, true)}
                                                    value={props.value} onChange={(event, value) => { props.onChange(value) }} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" style={{ overflowWrap: 'anywhere' }} />)} />,
                                            render: data =>
                                                <Typography title={data.userPrincipalId} color="textPrimary" style={{ fontFamily: "Arial" }}> {getUserFullName(data.userPrincipalId, true)}</Typography>
                                        },
                                        {
                                            title: 'Role', field: 'role', align: 'left', validate: rowData => rowData.role ? true : 'Role is required',
                                            editComponent: props =>
                                                <Autocomplete ChipProps={{ color: "info" }} size="small" clearOnEscape id="role" options={[GlobalRole.Admin, GlobalRole.ERMTeamMember, GlobalRole.Sponsor, GlobalRole.RiskIdentificationSheetDisplayAll, GlobalRole.RiskMonitoringSheetDisplayAll]}
                                                    value={props.value} onChange={(event, value) => { props.onChange(value) }} renderInput={(params) => (<TextField {...params} variant="outlined" color="primary" style={{ overflowWrap: 'anywhere' }} />)} />,
                                            render: data =>
                                                <Typography color="textPrimary" style={{ fontFamily: "Arial" }}> {data.role}</Typography>
                                        }
                                    ]}
                                    data={userRoles}
                                    editable={{
                                        onRowAdd: (newData) => addRecord(newData),
                                        onRowDelete: (oldData) => deleteRecord(oldData),
                                    }}

                                    localization={{ header: { actions: '' } }}

                                    options={{
                                        toolbarButtonAlignment: "left", searchFieldAlignment: 'left', paginationAlignment: "flex-start",
                                        pageSize: 7, pageSizeOptions: [5,7, 10, 20, 30, 50, 100], emptyRowsWhenPaging: false, showTitle: false, rowStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, editCellStyle: { fontSize: 12, fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'" }, actionsCellStyle: { width: '50px', color: infoColor }, headerStyle: { color: infoColor },
                                        exportAllData: true, exportMenu: [
                                            { label: 'Export PDF', exportFunc: (cols, datas) => { ExportPdf(cols, datas, "UserProfiles") } },
                                            { label: 'Export CSV', exportFunc: (cols, datas) => { ExportCsv(cols, datas, "UserProfiles") } }
                                        ],
                                        search:false
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </VerticalScrollableComponent>
                <br />

            </Paper>
        </Box>
    )
}
