import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, UserRole } from "../helpers/Interfaces";
import { showBackDrop, callServerAPI, getUserEmail } from "../helpers/Utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AxiosError } from "axios";

const MySwal = withReactContent(Swal);

export const getAllUserRoles = async () => {
    return await new Promise<UserRole[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading User Roles"));
        callServerAPI<ServerAPIResponse<UserRole[]>>('GET', '/api/UserProfile/GetAllUserRoles', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export async function removeUserFromRole(userRole: UserRole) {    
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop(`Removing ${getUserEmail(userRole.userPrincipalId)} to ${userRole.role} Role`));
        await callServerAPI<ServerAPIResponse<any>>('DELETE', `/api/UserProfile/RemoveUserFromRole?role=${userRole.role}&userPrincipalId=${window.encodeURIComponent(userRole.userPrincipalId)}`, null, false)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(`${getUserEmail(userRole.userPrincipalId)} removed from ${userRole.role} Role`, { variant: 'success' });
                resolve()
            })
            .catch((error: AxiosError<ServerAPIResponse<any>>) => {
                reject()
            })
    })
}

export async function addUserToRole(userRole: UserRole) {     
    return await new Promise<UserRole>(async (resolve, reject) => {
        MySwal.fire(showBackDrop(`Adding ${getUserEmail(userRole.userPrincipalId)} to ${userRole.role} Role`));
        await callServerAPI<ServerAPIResponse<UserRole>>('POST', `/api/UserProfile/AddUserToRole?role=${userRole.role}&userPrincipalId=${window.encodeURIComponent(userRole.userPrincipalId)}`, null, false)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(`${getUserEmail(userRole.userPrincipalId)} added to ${userRole.role} Role`, { variant: 'success' });
                resolve(response.data.data)
            })
            .catch((error: AxiosError<ServerAPIResponse<any>>) => {
                reject()
            })
    })
}

