import { Button, Paper, Stack, Typography } from "@mui/material";
import { AppPlatform, RoutePathNames } from "../helpers/Constants";
import { callServerGetLoggedonUser, fireAxiosErrorMySwal, showBackDrop } from "../helpers/Utils";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Login } from "@mui/icons-material";
import { VerticalScrollableComponent } from "../layouts/VerticalScrollableComponent";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "../store/store";
import { enqueueSnackbar } from "notistack";

const MySwal = withReactContent(Swal);

export function LoginTepngUser() {
    const navigate = useNavigate()
    const appConfig = useAppSelector(state => state.appConfig.value);

    useEffect(() => {
        MySwal.fire(showBackDrop("User Sign in ongoing"));
        callServerGetLoggedonUser()
            .then(response => {
                MySwal.close();
                const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
                localStorage.removeItem('loginRedirectedFromUrl');
                if (loginRedirectedFromUrl != null) navigate(loginRedirectedFromUrl ?? "/" + RoutePathNames.HOMEPAGE)
            })
            .catch(function (error) {
                MySwal.close();
                if (appConfig.APP_PLATFORM === AppPlatform.ActiveDirectoryDomain)
                    enqueueSnackbar('Automatic user sign in failed. Please refresh page or contact Administrator', { variant: 'error' });
                if (error?.response?.status !== 401)
                    fireAxiosErrorMySwal(error)
            })
    }, [navigate, appConfig.APP_PLATFORM, appConfig.SERVER_URL])

    const login = useCallback(async () => {
        MySwal.fire(showBackDrop("User Sign in ongoing"));
        if (appConfig.APP_PLATFORM === AppPlatform.Azure) {
            let url = new URL(window.location.origin + '/' + RoutePathNames.LOGIN)
            // microsoft login redirect - the frond end url with the /login endpoint needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect
            window.location.replace(appConfig.SERVER_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));
        }
    }, [appConfig.APP_PLATFORM, appConfig.SERVER_URL])

    return (
        <Paper className="Form"  >
            <br />
            <VerticalScrollableComponent viewPortHeightOffset={161}>
                <Stack direction="column" spacing={2} style={{ width: '100%', height: '100%', justifyContent: "center" }}  >
                    <div key={1} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                        <img style={{ width: 400 }} src='../../assets/images/logo_large_center.png' alt="logo_large_center.png" />
                    </div>
                    {appConfig.APP_PLATFORM === AppPlatform.Azure &&
                        <>
                            <div key={2} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                                <Typography align="center" sx={{ fontSize: 14 }} color="textPrimary" > Click here to logon via Microrosft Entra ID </Typography>
                            </div>
                            <div key={3} style={{ display: "flex", padding: 5, alignItems: "center", justifyContent: "center" }}>
                                {<Button onClick={() => login()} variant="contained" size="medium" color="secondary"><Login /> &nbsp; Login </Button>}
                            </div>
                        </>
                    }

                </Stack>
            </VerticalScrollableComponent>
            <br />
        </Paper>
    );
}