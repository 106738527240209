import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { useEffect, useState, useMemo } from 'react';
import { loadAppConfigFromSettingsFile } from './helpers/Utils';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { RiskManRoutes } from './routes/RiskManRoutes';
import { AppTheme } from './layouts/theme';

function App() {
  const theme = useMemo(() => AppTheme(), [])
  const [appConfigLoaded, setAppConfigLoaded] = useState(false);

  useEffect(() => {
    const launchLoadAppConfig = async () => {
      await loadAppConfigFromSettingsFile()
      setAppConfigLoaded(true)
    }
    launchLoadAppConfig()
  }, [])


  return (
    <>
      {appConfigLoaded &&
        <div className="app">
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider>
                <RiskManRoutes />
              </SnackbarProvider>
            </ThemeProvider>
          </Router>
        </div>
      }
    </>
  );
}

export default App