import { Typography, Box, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getRiskAssessment } from "../api-services/RiskAssessmentApi";
import { RiskAssessmentStatus } from "../helpers/Constants";
import { RiskAssessment } from "../helpers/Interfaces";
import { useAppSelector } from "../store/store";
import { VoteRiskView } from "../components/risk-assessment-voting/VoteRiskView";
import { NoOngoingVotingView } from "../components/risk-assessment-voting/NoOngoingVotingView";
import { SubmittedVoteView } from "../components/risk-assessment-voting/SubmittedVoteView";
import { NotAuthorisedToVote } from "../components/risk-assessment-voting/NotAuthorisedToVote";
import { VotingInstructionView } from "../components/risk-assessment-voting/VotingInstructionView";


export function VoteRiskAssessment() {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const location = useLocation();
    let queryParams = new URLSearchParams(location.search);
    const assessmentIdForVoting: number | null = queryParams.get("assessmentIdForVoting") == null ? null : Number(queryParams.get("assessmentIdForVoting"))
    //states
    const [riskAssessment, setRiskAssessment] = useState<RiskAssessment | null>(null)
    const [voteSubmittedSuccessfully, setVoteSubmittedSuccessfully] = useState(false)
    const [displayInstructionPage, setDisplayInstructionPage] = useState(true)

    useEffect(() => {
        if (assessmentIdForVoting == null) return
        getRiskAssessment(assessmentIdForVoting).then(result => {
            setRiskAssessment(result)
        });
    }, [assessmentIdForVoting]);


    function getViewToLoad() {
        if (riskAssessment == null || loggedOnUserProfile == null) return
        if (riskAssessment.status !== RiskAssessmentStatus.VOTING || riskAssessment.votingEndDate == null || new Date(riskAssessment.votingEndDate) < new Date())
            return <NoOngoingVotingView riskAssessment={riskAssessment} />
        if (riskAssessment.votingRespondersList.find(r => r === loggedOnUserProfile.id) == null)
            return <NotAuthorisedToVote riskAssessment={riskAssessment} />
        if (voteSubmittedSuccessfully === true)
            return <SubmittedVoteView />
        if (displayInstructionPage)
            return <VotingInstructionView riskAssessment={riskAssessment} onClickBeginVoting={() => setDisplayInstructionPage(false)} />
        else
            return <VoteRiskView riskAssessment={riskAssessment} onVotingSubmitted={result => { setVoteSubmittedSuccessfully(true); setRiskAssessment(result) }} />
    }

    return (
        riskAssessment &&
        <Paper className="Form" >
            <Box className="BoldFormHeader">
                <Typography className="BoldFormHeaderDetails"> {riskAssessment.assessmentName} - Risk Consensus &amp; Rating</Typography>
            </Box>
            {getViewToLoad()}
        </Paper >
    )
}
