import { formatLongDateString, getUserFullName, formatShortDateString, getRiskAssessmentStatusString, getAssessmentTypeString, getEntitiesListAsSpan } from "../../helpers/Utils";
import { Grid, Typography } from "@mui/material";
import { RiskAssessment } from "../../helpers/Interfaces";


interface Props {
    riskAssessment: RiskAssessment
}

export function ViewRiskAssessment({ riskAssessment }: Props) {
    return (
        <Grid id="CreateEditRiskContainer" container spacing={4} className="FormDetails">
            <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Assessment Name</Typography>
                <Typography color="textPrimary" >{riskAssessment.assessmentName ?? ''} </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color="textSecondary" >Assessment Type</Typography>
                <Typography color="textPrimary" >{getAssessmentTypeString(riskAssessment.assessmentType)} </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color="textSecondary" >Status:</Typography>
                <Typography color="textPrimary" >{getRiskAssessmentStatusString(riskAssessment.status)} </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Description</Typography>
                <Typography color="textPrimary" >{riskAssessment.description ?? ''} </Typography>
            </Grid>
            {riskAssessment.drillFilterStartDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Drill Filter Start Date</Typography>
                <Typography color="textPrimary" >{formatLongDateString(riskAssessment.drillFilterStartDate, true)} </Typography>
            </Grid>}
            {riskAssessment.drillFilterEndDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Drill Filter End Date</Typography>
                <Typography color="textPrimary" >{formatLongDateString(riskAssessment.drillFilterEndDate, true)} </Typography>
            </Grid>}
            {riskAssessment.drillFilterEntitiesImpacted.length > 0 && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Drill Filter Entities Impacted</Typography>
                <Typography color="textPrimary" >
                    {getEntitiesListAsSpan(riskAssessment.drillFilterEntitiesImpacted, ',', 4)}
                </Typography>
            </Grid>}
            {riskAssessment.identificationRespondersList.length > 0 && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Identification Participants <span style={{ color: 'red' }}>{riskAssessment.notifyIdentificationRespondersByEmail && '(to be Notified by Email)'}</span></Typography>
                {riskAssessment.allStaffIdentification && <Typography color="textPrimary" > All Staff are Participants </Typography>}
                {riskAssessment.allPermanentStaffIdentification && <Typography color="textPrimary" > All Permanent Staff are Participants </Typography>}
                {!riskAssessment.allPermanentStaffIdentification && !riskAssessment.allStaffIdentification && <Typography color="textPrimary" >{riskAssessment.identificationRespondersList.map(i => getUserFullName(i, true)).join(', ')} </Typography>}
            </Grid>}
            {riskAssessment.identificationStartDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Identification Commencement</Typography>
                <Typography color="textPrimary" >{formatLongDateString(riskAssessment.identificationStartDate, true) + (riskAssessment.identificationStartedBy == null ? '' : ' (Identification Process started by: ' + getUserFullName(riskAssessment.identificationStartedBy) + ')')} </Typography>
            </Grid>}
            {riskAssessment.identificationEndDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Identification End Date</Typography>
                <Typography color="textPrimary" >{formatLongDateString(riskAssessment.identificationEndDate, true)} </Typography>
            </Grid>}
            {riskAssessment.votingRespondersList.length > 0 && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Voting Partcipants <span style={{ color: 'red' }}>{riskAssessment.notifyVotingRespondersByEmail && '(to be Notified by Email)'}</span></Typography>
                <Typography color="textPrimary" >{riskAssessment.votingRespondersList.map(i => getUserFullName(i, true)).join(', ')} </Typography>
            </Grid>}
            {riskAssessment.votingStartDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Voting Commencement</Typography>
                <Typography color="textPrimary" >{formatLongDateString(riskAssessment.votingStartDate, true) + (riskAssessment.votingStartedBy == null ? '' : ' (Voting Process started by: ' + getUserFullName(riskAssessment.votingStartedBy) + ')')} </Typography>
            </Grid>}
            {riskAssessment.votingEndDate && <Grid item xs={12}>
                <Typography color="textSecondary" >Risk Voting End Date</Typography>
                <Typography color="textPrimary" >{formatLongDateString(riskAssessment.votingEndDate, true)} </Typography>
            </Grid>}
            <Grid item xs={12}>
                <Typography color="textSecondary" >Created</Typography>
                {riskAssessment.createdBy && <Typography color="textPrimary" title={riskAssessment.createdBy} >{getUserFullName(riskAssessment.createdBy) + ' - ' + formatShortDateString(riskAssessment.createdDate ?? new Date())}</Typography>}
            </Grid>
            <Grid item xs={12}>
                <Typography color="textSecondary" >Last Updated</Typography>
                {riskAssessment.updatedBy && <Typography color="textPrimary" title={riskAssessment.updatedBy} >{getUserFullName(riskAssessment.updatedBy) + ' - ' + formatShortDateString(riskAssessment.updatedDate ?? new Date())} </Typography>}
            </Grid>
        </Grid >
    )

}
