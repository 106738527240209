import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { CMDBUser, Entity, MainMenuItem, ImpactArea, RatingDescription, ReportPage, Risk, RiskAssessment, RiskChange, RiskImpactAreaRating, ServerAPIResponse, UserLoginResponse, UserProfile, AppConfig } from "./Interfaces";
import { AssessmentTypes, RoutePathNames, ReportPageIds, RiskActionStatus, RiskAssessmentStatus, RiskPrioritisationZones, RiskStatus, RiskSortType, RiskGroupFilters, AppPlatform } from "./Constants";
import { ReactElement } from "react";
import withReactContent, { ReactSweetAlertOptions } from "sweetalert2-react-content";
import Swal, { SweetAlertResult, SweetAlertInput } from "sweetalert2";
import { store } from "../store/store";
import { setLoggedOnUserProfile } from "../store/loggedOnUserProfile";
import { setRiskRelatedTables } from "../store/riskRelatedTables";
import { infoColor, secondaryColor } from "../layouts/theme";
import { setAppConfig } from "../store/appConfig";
import { setServerAppConfig } from "../store/ServerAppConfig";
import { setCmdbUsers } from "../store/cmdbUsers";
import { setCmdbUsersId } from "../store/cmdbUsersId";


export async function loadAppConfigFromSettingsFile() {
    const dispatch = store.dispatch;
    await fetch("appsettings.json").then<AppConfig>(r => r.json())
        .then((data) => {
            const appConfig: AppConfig = {
                SERVER_URL: data.SERVER_URL ?? process.env.REACT_APP_SERVER_URL ?? "",
                ENVIRONMENT: data.ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT ?? "",
                APP_VERSION: data.APP_VERSION ?? process.env.REACT_APP_VERSION ?? "",
                APP_LAST_UPDATED: Number(data.APP_LAST_UPDATED ?? process.env.REACT_APP_LAST_UPDATED ?? 0) * 1000,
                APP_PLATFORM: data.APP_PLATFORM ?? process.env.REACT_APP_PLATFORM ?? "",
                REPORTS_BASE_URL: data.REPORTS_BASE_URL ?? process.env.REACT_APP_REPORTS_BASE_URL ?? "",
                SUPPORT_CONTACTS: data.SUPPORT_CONTACTS ?? process.env.REACT_APP_SUPPORT_CONTACTS ?? "",
                USERGUIDE_RISKIDENTIFICATION: data.USERGUIDE_RISKIDENTIFICATION ?? process.env.REACT_APP_USERGUIDE_RISKIDENTIFICATION ?? "",
                USERGUIDE_RISKIDENTIFICATIONASSESSMENT: data.USERGUIDE_RISKIDENTIFICATIONASSESSMENT ?? process.env.REACT_APP_USERGUIDE_RISKIDENTIFICATIONASSESSMENT ?? "",
                USERGUIDE_ENTITYRISKIDENTIFICATION: data.USERGUIDE_ENTITYRISKIDENTIFICATION ?? process.env.REACT_APP_USERGUIDE_ENTITYRISKIDENTIFICATION ?? "",
            }
            dispatch(setAppConfig(appConfig));
        })
        .catch(function (error: AxiosError<ServerAPIResponse<any>>) {
            console.log(error);
        });
}


export async function callServerAPI<Type>(method: Method, url: string, data: any = null, doNotThrowErrorOnFailure: boolean, responseType: import("axios").ResponseType = 'json'): Promise<AxiosResponse<Type>> {
    return await new Promise(async (resolve, reject) => {
        const appConfig = store.getState().appConfig.value;
        let config: AxiosRequestConfig = {}

        config.method = method; config.url = appConfig.SERVER_URL + url; config.withCredentials = true; config.data = data
        if (responseType) config.responseType = responseType
        await axios.request(config)
            .then(function (response: AxiosResponse<Type>) {
                resolve(response)
            })
            .catch(function (error: AxiosError<ServerAPIResponse<any>>) {
                console.log(error);
                if (error?.response?.status === 401) {
                    localStorage.setItem("loginRedirectedFromUrl", window.location.href);
                    let url = new URL(window.location.origin + '/' + RoutePathNames.LOGIN)
                    window.location.replace(url.href);
                }
                else{
                    fireAxiosErrorMySwal(error)
                    if (!doNotThrowErrorOnFailure) reject(error)
                }
            });
    });
}

export async function callServerGetLoggedonUser(): Promise<AxiosResponse<ServerAPIResponse<UserLoginResponse>>> {
    const dispatch = store.dispatch;
    const appConfig = store.getState().appConfig.value;
    return await new Promise(async (resolve, reject) => {
        var config: AxiosRequestConfig = {}
        config.method = 'GET'; config.data = null; config.url = appConfig.SERVER_URL + '/api/UserProfile/GetLoggedonUser'; config.withCredentials = true
        await axios.request(config)
            .then(async function (response: AxiosResponse<ServerAPIResponse<UserLoginResponse>>) {
                dispatch(setRiskRelatedTables(response.data.data.riskRelatedTables));
                dispatch(setCmdbUsers(new Map(response.data.data.cmdbUsers.map(u => [u.id, u]))));
                dispatch(setCmdbUsersId(response.data.data.cmdbUsers.map(u => u.id)));
                dispatch(setLoggedOnUserProfile(response.data.data.userProfile));
                dispatch(setServerAppConfig(response.data.data.serverAppConfig));
                resolve(response)
            })
            .catch(function (error: AxiosError<ServerAPIResponse<any>>) {
                console.log(error);
                reject(error)
            });
    });
}

export function callServerTepngUserLogout() {
    const dispatch = store.dispatch;
    const appConfig = store.getState().appConfig.value;
    dispatch(setLoggedOnUserProfile(null));
    let url = new URL(window.location.origin)
    // needed to logout of microsoft - the frond end url needs to be added to Azure App registration Redirect URIs to allow for logout redirect
    if (appConfig.APP_PLATFORM === AppPlatform.Azure)
        window.location.replace(appConfig.SERVER_URL + '/.auth/logout?post_logout_redirect_uri=' + window.encodeURIComponent(url.href));
}


export async function fireAxiosErrorMySwal(error: AxiosError<ServerAPIResponse<any>>) {
    if (error?.response?.data instanceof Blob && error?.response?.data?.type && error?.response?.data?.type.toLowerCase().indexOf('json') !== -1) {
        var errorString = JSON.parse(await error.response.data.text());
        showAlertError(errorString.message)
    }
    else {
        if (error?.response == null)
            showAlertError(error.message)
        else if (error?.response?.data?.message == null)
            showAlertError(error.response.status + ' ' + error.response.statusText)
        else
            showAlertError(error?.response?.data?.message)
    }
}

export function showAlertError(message: string) {
    const MySwal = withReactContent(Swal);
    MySwal.fire(alertError(message))
}

function alertError(message: string): ReactSweetAlertOptions {
    return {
        icon: 'warning',
        title: '',
        html: `<small>${message}</small>`,
        showDenyButton: true,
        denyButtonText: `<small>OK</small>`,
        showConfirmButton: false,
    }
}

export function showAlertInfo(message: string, title: string = ''): Promise<SweetAlertResult<any>> {
    const MySwal = withReactContent(Swal);
    return MySwal.fire({
        title: `<span style="color:${infoColor}; font-size:16px">${title}</span>`,
        html: `<small> ${message}</small>`,
        icon: 'info',
        iconColor: '#999999',
        showDenyButton: true,
        denyButtonText: `<small>OK</small>`,
        denyButtonColor: infoColor,
        showConfirmButton: false,
    })
}

export function showConfirmBox(message: string, title: string = 'Confirm', yesButtonText: string = 'Yes', noButtonText: string = 'No'): Promise<SweetAlertResult<any>> {
    const MySwal = withReactContent(Swal);
    return MySwal.fire({
        title: `<span style="color:${infoColor}; font-size:16px">${title}</span>`,
        html: `<small> ${message}</small>`,
        icon: 'question',
        iconColor: '#999999',
        cancelButtonColor: infoColor,
        cancelButtonText: noButtonText,
        showCancelButton: true,
        confirmButtonColor: secondaryColor,
        confirmButtonText: yesButtonText

    })
}

export function showInputBox(message: string, title: string = 'Confirm', inputPlaceholder: string = '', okButtonText: string = 'OK', cancelButtonText: string = 'Cancel', inputType: SweetAlertInput = 'textarea'): Promise<SweetAlertResult<any>> {
    const MySwal = withReactContent(Swal);
    return MySwal.fire({
        title: `<span style="font-size:16px">${title}</span>`,
        html: `<small style="color:#cc0000"> ${message}</small>`,
        confirmButtonText: okButtonText,
        confirmButtonColor: secondaryColor,
        cancelButtonColor: infoColor,
        cancelButtonText: cancelButtonText,
        showCancelButton: true,
        input: inputType,
        inputPlaceholder: inputPlaceholder,
        allowEnterKey: true,
        allowEscapeKey: true,
        inputAutoTrim: true,
    })
}


export function showBackDrop(message: string) {
    return {
        title: message,
        html: '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100px;" src="../assets/images/loading-icon-red.gif" /><br> <strong> Please Wait... </strong> </div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false,
    }
}

export function getAssessmentTypeString(assessmentType: number) {
    switch (assessmentType) {
        case AssessmentTypes.AFFILIATE: return 'Affiliate';
        case AssessmentTypes.PROJECT: return 'Project';
        case AssessmentTypes.DRILL: return 'Drill';
        case AssessmentTypes.OTHERS: return 'Others';
        default: return null;
    }
}

export function getRiskStatusString(status: number) {
    switch (status) {
        case RiskStatus.IDENTIFIED: return 'Identified';
        case RiskStatus.SUBMITTED: return 'Submitted';
        case RiskStatus.APPROVED: return 'Approved';
        case RiskStatus.CHAMPION_NOMINATED: return 'Champion Nominated';
        case RiskStatus.CHAMPION_NOMINATION_REJECTED: return 'Champion Nomination Rejected';
        case RiskStatus.ASSIGNED_TO_CHAMPION: return 'Assigned to Champion';
        case RiskStatus.ACTION_PLAN_SUBMITTED: return 'Action Plan Submitted';
        case RiskStatus.ACTION_PLAN_REJECTED: return 'Action Plan Rejected';
        case RiskStatus.ACTION_PLAN_APPROVED: return 'Action Plan Approved';
        case RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED: return 'Action Plan Implementation Submitted';
        case RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR: return 'Action Plan Implementation Rejected';
        case RiskStatus.UNEXPOSED: return 'Unexposed'
        default: return null;
    }
}

export function getRiskAssessmentStatusString(status: number) {
    switch (status) {
        case RiskAssessmentStatus.NEW: return 'New';
        case RiskAssessmentStatus.IDENTIFICATION: return 'Identification';
        case RiskAssessmentStatus.AGGREGATION: return 'Aggregation';
        case RiskAssessmentStatus.VOTING: return 'Voting';
        case RiskAssessmentStatus.APPROVAL: return 'Approval';
        case RiskAssessmentStatus.CLOSED: return 'Closed';
        default: return null;
    }
}

export function getRiskActionStatusString(status: number, risk: Risk) {
    switch (status) {
        case RiskActionStatus.DRAFT: return 'Draft';
        case RiskActionStatus.SUBMITTED: return 'Submitted';
        case RiskActionStatus.REJECTED: return 'Rejected';
        case RiskActionStatus.APPROVED: return 'Approved';
        case RiskActionStatus.IMPLEMENTATION_APPROVED: return 'Implementation Approved';
        default: return null;
    }
}

export function getRiskPrioritisationZoneString(prioritisation: number) {
    switch (prioritisation) {
        case RiskPrioritisationZones.DELEGATION_ZONE: return 'Delegation Zone';
        case RiskPrioritisationZones.VIGILANCE_ZONE: return 'Vigilance Zone';
        case RiskPrioritisationZones.VERIFICATION_ZONE: return 'Verification Zone';
        case RiskPrioritisationZones.PRIORITY_REMEDIATION: return 'Priority Remediation Zone';
        default: return null;
    }
}



/**
 * Returns the description of the rating of an impact area of a Risk.
 */
export function getImpactAreaRatingDescription(riskImpactArea: RiskImpactAreaRating | null | undefined, impactArea: ImpactArea): string {
    switch (riskImpactArea?.rating) {
        case 6:
            return riskImpactArea.rating + " - " + impactArea.rating6Description;
        case 5:
            return riskImpactArea.rating + " - " + impactArea.rating5Description;
        case 4:
            return riskImpactArea.rating + " - " + impactArea.rating4Description;
        case 3:
            return riskImpactArea.rating + " - " + impactArea.rating3Description;
        case 2:
            return riskImpactArea.rating + " - " + impactArea.rating2Description;
        case 1:
            return riskImpactArea.rating + " - " + impactArea.rating1Description;
        default:
            return 'N/A';
    }
}

/**
 * Returns the description for the Impact Likelihood or Mitigation overall rating for a Risk.
 */
export function getRatingDescription(rating: number, ratingDescriptions: RatingDescription[], ratingType: string): string {
    var ratingDescription = ratingDescriptions.find(r => r.ratingType === ratingType)
    if (ratingDescription == null) return ''
    switch (rating) {
        case 6:
            return ratingDescription.rating6Description;
        case 5:
            return ratingDescription.rating5Description;
        case 4:
            return ratingDescription.rating4Description;
        case 3:
            return ratingDescription.rating3Description;
        case 2:
            return ratingDescription.rating2Description;
        case 1:
            return ratingDescription.rating1Description;
        default:
            return 'N/A';
    }
}

/**
 * Returns the description for the Impact Likelihood or Mitigation overall rating for a Risk.
 */
export function getRatingDescriptionHtml(rating: number, ratingDescriptions: RatingDescription[], ratingType: string): JSX.Element {
    var ratingDescription = ratingDescriptions.find(r => r.ratingType === ratingType)
    if (ratingDescription == null) return <></>
    var description = ''
    switch (rating) {
        case 6:
            description = ratingDescription.rating6Description
            break;
        case 5:
            description = ratingDescription.rating5Description;
            break;
        case 4:
            description = ratingDescription.rating4Description;
            break;
        case 3:
            description = ratingDescription.rating3Description;
            break;
        case 2:
            description = ratingDescription.rating2Description;
            break;
        case 1:
            description = ratingDescription.rating1Description;
            break;
        default:
            description = 'N/A';
    }

    return (
        <ul>
            {description.trim().split('\n').map(e => e.trim() === '' ? '' : <li style={{ marginBottom: 10 }} > {e} </li>)}
        </ul>
    )
}


export function checkGlobalMenuEnabled(mainMenuItem: MainMenuItem, userLoggedOn: UserProfile, entities: Entity[]): boolean {
    switch (mainMenuItem.pathName) {
        case RoutePathNames.RISK_IDENTIFICATION:
            return true;
        case RoutePathNames.RISK_ASSESSMENT:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.RISK_ASSIGNMENT:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.RISK_TREATMENT:
            return true;
        case RoutePathNames.RISK_MONITORING:
            return true;
        case RoutePathNames.REPORTS:
            return userLoggedOn.isERMTeamMember || userLoggedOn.isSponsor || userLoggedOn.riskMonitoringSheetDisplayAll || checkUserIsEntityHead(entities, userLoggedOn) || checkUserIsEntityCorrespondent(entities, userLoggedOn) || checkUserIsEntityDirector(entities, userLoggedOn);
        case RoutePathNames.EMAIL_TEMPLATES:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.CATEGORIES:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.BUSINESS_OBJECTIVES:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.ENTITIES:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.RATINGS:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.IMPACTAREAS:
            return userLoggedOn.isERMTeamMember;
        case RoutePathNames.USERPROFILES:
            return userLoggedOn.isAdmin;
        default:
            return true
    }
}


export function checkReportPageEnabled(reportPage: ReportPage, entities: Entity[], userLoggedOn: UserProfile): boolean {
    switch (reportPage.pageId) {
        case ReportPageIds.RISKS_UNIVERSE:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.FREQUENCY_OF_RISK_MENTIONS:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_ANALYSIS:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_CONSENSUS_OUTPUT:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_PRIORITISATION_DETAILS:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_VOTING_DISPERSION:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_VOTING_BREAKDOWN:
            return userLoggedOn.isERMTeamMember;
        case ReportPageIds.RISK_VOTING_CONSENSUS:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_REGISTER:
            return checkUserIsEntityCorrespondent(entities, userLoggedOn) || checkUserIsEntityHead(entities, userLoggedOn) || checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.GLOBAL_EXPOSURE_DASHBOARD:
            return checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        case ReportPageIds.RISK_TREND:
            return checkUserIsEntityCorrespondent(entities, userLoggedOn) || checkUserIsEntityHead(entities, userLoggedOn) || checkUserIsEntityDirector(entities, userLoggedOn) || userLoggedOn.isERMTeamMember || userLoggedOn.riskMonitoringSheetDisplayAll || userLoggedOn.isSponsor;
        default:
            return false;
    }
}

export function checkSubmitRiskValidation(risk: Risk, user:UserProfile|null|undefined): boolean {
    if(user == null) return false
    var allowSubmit = false;
    var isEntityHeadForEntityRisk = checkUserIsEntityHead(risk.entitiesImpacted, user);
    if (risk.status === RiskStatus.IDENTIFIED && !risk.isEntityRisk && (risk.createdBy == null || risk.createdBy === user.id)) allowSubmit = true;
    if (risk.status === RiskStatus.IDENTIFIED && risk.isEntityRisk && isEntityHeadForEntityRisk) allowSubmit = true;
    // if (val) checks that val has a truthy value. falsy values are: null, undefined, NaN, "", 0, false
    return Boolean(allowSubmit && risk.name && risk.description && risk.businessObjectiveImpacted != null && risk.category != null && risk.entitiesImpacted.length !== 0 && risk.impact && risk.likelihood && risk.mitigation && (risk.mitigation === 1 || risk.existingMitigations))
}

export function checkRiskCreateable(riskAssessment: RiskAssessment | null, userLoggedOn: UserProfile): boolean {
    if (riskAssessment == null) return true
    // allow to add in risk assessment if riskassessment is in identification phase and user is an identfication responder/ERM Team member
    if (riskAssessment.status === RiskAssessmentStatus.IDENTIFICATION && riskAssessment.identificationEndDate != null && new Date(riskAssessment.identificationEndDate) >= new Date() && (riskAssessment.identificationRespondersList.find(r => r === userLoggedOn.id) != null || userLoggedOn.isERMTeamMember)) return true;
    return false;
}

export function checkRiskEditable(risk: Risk, riskAssessmentForRiskIdentification: RiskAssessment | null, userLoggedOn: UserProfile): boolean {
    //if risk is in a riskassessment but the page wasnt loaded in a risk assessment identification mode
    if (risk.sourceAssessment != null && riskAssessmentForRiskIdentification == null) return false

    if (riskAssessmentForRiskIdentification != null) { // allow to update risk in risk assessment if riskassessment is in identification phase, risk is in identified status and user is original identifier
        if (riskAssessmentForRiskIdentification.status === RiskAssessmentStatus.IDENTIFICATION && riskAssessmentForRiskIdentification.identificationEndDate != null && new Date(riskAssessmentForRiskIdentification.identificationEndDate) >= new Date() && risk.status === RiskStatus.IDENTIFIED && risk.createdBy === userLoggedOn.id) return true;
    }
    else { // allow to update risk drill if risk is in identified status and user is original identifier or User is EntityHead/Correspondent for an Entity Risk
        if (risk.status === RiskStatus.IDENTIFIED && risk.createdBy === userLoggedOn.id) return true;
        let isCorrespondentForEntityRisk = checkUserIsEntityCorrespondent(risk.entitiesImpacted, userLoggedOn);
        let isEntityHeadForEntityRisk = checkUserIsEntityHead(risk.entitiesImpacted, userLoggedOn);
        if (risk.status === RiskStatus.IDENTIFIED && risk.isEntityRisk && (isCorrespondentForEntityRisk || isEntityHeadForEntityRisk)) return true;
    }
    return false;
}

export function checkRiskDeleteable(risk: Risk, riskAssessmentForRiskIdentification: RiskAssessment | null, userLoggedOn: UserProfile): boolean {
    //if risk is in a riskassessment but the page wasnt loaded in a risk assessment identification mode
    if (risk.sourceAssessment != null && riskAssessmentForRiskIdentification == null) return false

    if (riskAssessmentForRiskIdentification != null) {
        // allow to delete risk in risk assessment if riskassessment is in identification phase, risk is in identified status and user is original identifier
        if (riskAssessmentForRiskIdentification.status === RiskAssessmentStatus.IDENTIFICATION && riskAssessmentForRiskIdentification.identificationEndDate != null && new Date(riskAssessmentForRiskIdentification.identificationEndDate) >= new Date() && risk.status === RiskStatus.IDENTIFIED && risk.createdBy === userLoggedOn.id) return true;
    }
    else {
        // allow to delete risk drill if risk is in identified status and user is original identifier or User is EntityHead/Correspondent for an Entity Risk
        if (risk.status === RiskStatus.IDENTIFIED && risk.createdBy === userLoggedOn.id) return true;
        let isCorrespondentForEntityRisk = checkUserIsEntityCorrespondent(risk.entitiesImpacted, userLoggedOn);
        let isEntityHeadForEntityRisk = checkUserIsEntityHead(risk.entitiesImpacted, userLoggedOn);
        if (risk.status === RiskStatus.IDENTIFIED && risk.isEntityRisk && (isCorrespondentForEntityRisk || isEntityHeadForEntityRisk)) return true;
    }
    return false;
}

export function checkSubmitRiskChangeValidation(riskChange: RiskChange): boolean {
    // if (val) checks that val has a truthy value. falsy values are: null, undefined, NaN, "", 0, false
    return Boolean(riskChange.changeJustification && riskChange.name && riskChange.description && riskChange.businessObjectiveImpacted != null && riskChange.category != null && riskChange.entitiesImpacted.length !== 0 && riskChange.impact && riskChange.likelihood && riskChange.mitigation && (riskChange.mitigation === 1 || riskChange.existingMitigations))
}

export function checkUserIsEntityCorrespondent(entities: Entity[], user: UserProfile|null|undefined): boolean {
    if(user == null) return false
    return entities?.find(e => e?.correspondentsList?.find(c => c === user.id) != null) != null
}

export function getCorrespondentEntitiesForLoggedOnUser(entities: Entity[], user: UserProfile|null|undefined): Entity[] {
    if(user == null) return []
    return entities?.filter(e => e?.correspondentsList?.find(c => c === user.id) != null)
}

export function checkUserIsEntityHead(entities: Entity[], user: UserProfile|null|undefined): boolean {
    if(user == null) return false
    return entities?.find(e => e?.entityHeadsList?.find(c => c === user.id) != null) != null
}

export function getEntityHeadEntitiesForLoggedOnUser(entities: Entity[], user: UserProfile|null|undefined): Entity[] {
    if(user == null) return []
    return entities?.filter(e => e?.entityHeadsList?.find(c => c=== user.id) != null)
}

export function checkUserIsRiskChampion(risk: Risk, user: UserProfile|null|undefined): boolean {
    if(user == null) return false
    return risk.champion === user.id || risk.alternateChampion === user.id
}

export function checkUserIsEntityDirector(entities: Entity[], user: UserProfile|null|undefined): boolean {
    if(user == null) return false
    return entities?.find(e => e?.director === user.id) != null
}

/**
 * Returns the fullname of a user
 */
export function getUserFullName(userId: string | null | undefined, includeIGG: boolean = true) {
    if (userId == null) return ''
    const cmdbUsers = store.getState().cmdbUsers.value;    
    var user = cmdbUsers.get(userId)
    return user == null ? userId : user.fullName + (includeIGG ? " (" + user.igg + ")" : '')
}

/**
 * Returns the email of a user
 */
export function getUserEmail(userId: string | null | undefined) {
    if (userId == null) return ''
    const cmdbUsers = store.getState().cmdbUsers.value;    
    var user = cmdbUsers.get(userId)
    return user == null ? userId : user.email
}

/**
 * Returns a descriptive message for a server error
 */
export function getDescriptiveErrorMessage(error: AxiosError<ServerAPIResponse<any>>): ReactElement {
    console.log(error)
    if (error.response === undefined) return <span>{error.toString()}</span>;
    return <span>{error.response.data.message ?? error.response.statusText + ' - ' + error.response.data}</span>
}

/**
 * Returns a plain span tag with text as InnerText
 */
export function generatePlainElementFromText(text: string): ReactElement {
    return <span>{text}</span>
}

export function getEntitiesListAsSpan(entities: Entity[], separator: string, extraSpaces: number): ReactElement[] {
    let html = []
    for (let i = 0; i < entities.length; i++)
        html.push(<span key={entities[i].id} title={entities[i].baseRadicalShort}>{entities[i].name + (i < entities.length - 1 ? separator : '')} {'\xa0'.repeat(extraSpaces)} </span>)
    return html
}

export function getUsersListAsSpan(users: CMDBUser[], separator: string, extraSpaces: number): ReactElement[] | undefined {
    if (users == null) return
    let html = []
    for (let i = 0; i < users.length; i++)
        html.push(<span key={users[i].email} title={users[i].email}>{users[i].firstName + ' ' + users[i].lastName + (i < users.length - 1 ? separator : '')} {'\xa0'.repeat(extraSpaces)} </span>)
    return html
}

export function convertHtmlToText(inputText: string): string {
    var returnText = "" + inputText;

    //-- remove BR tags and replace them with line break
    returnText = returnText.replace(/<br>/gi, "\n");
    returnText = returnText.replace(/<br\s\/>/gi, "\n");
    returnText = returnText.replace(/<br\/>/gi, "\n");

    //-- remove P and A tags but preserve what's inside of them
    returnText = returnText.replace(/<p.*>/gi, "\n");
    returnText = returnText.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 ($1)");

    //-- remove all inside SCRIPT and STYLE tags
    returnText = returnText.replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, "");
    returnText = returnText.replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, "");
    //-- remove all else
    returnText = returnText.replace(/<(?:.|\s)*?>/g, "");

    //-- get rid of more than 2 multiple line breaks:
    returnText = returnText.replace(/(?:(?:\r\n|\r|\n)\s*){2,}/gim, "\n\n");

    //-- get rid of more than 2 spaces:
    returnText = returnText.replace(/ +(?= )/g, '');

    //-- get rid of html-encoded characters:
    returnText = returnText.replace(/&nbsp;/gi, " ");
    returnText = returnText.replace(/&amp;/gi, "&");
    returnText = returnText.replace(/&quot;/gi, '"');
    returnText = returnText.replace(/&lt;/gi, '<');
    returnText = returnText.replace(/&gt;/gi, '>');

    return returnText;
}


export function truncateTextToWhiteSpace(text: string, numChars: number): string {
    if (text.length <= numChars)
        return text
    var truncatedText = text.substring(0, numChars)
    return truncatedText.substring(0, truncatedText.lastIndexOf(' ')) + " ..."
}

export function formatLongDateString(dtStr: Date, includeTime = false): string {
    var dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    var timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}

export function formatShortDateString(dtStr: Date, includeTime = false): string {
    var dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    var timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}

export function formatTimeString(dtStr: Date): string {
    var timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    var dt = new Date(dtStr)
    return dt.toLocaleTimeString('en-GB', timeOptions)
}

export function getCurrentTimeString() {
    const date = new Date()
    return ('0' + (date.getUTCHours())).slice(-2) + ':' + ('0' + (date.getUTCMinutes())).slice(-2) + ':' + ('0' + (date.getUTCSeconds())).slice(-2) + ':' + date.getUTCMilliseconds()
}

export function formatDateyyyymmdd(date: Date | null | undefined) {
    if (date == null) return ""
    date = new Date(date);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = (date.getFullYear() + '').padStart(4, '0')
    const hour = (date.getHours() + '').padStart(2, '0')
    const min = (date.getMinutes() + '').padStart(2, '0')
    return `${year}-${month}-${day}T${hour}:${min}`;
}

export function getFilteredAndSortedRisks(loggedOnUserProfile: UserProfile | null, risks: Risk[], entityFilter: string, categoryFilter: string, statusFilter: string, riskAssessmentFilter: string, prioritisationFilter: string, riskGroupFilter: RiskGroupFilters, riskSortType: RiskSortType) {
    let _filteredRisks = risks.filter(risk =>
        (entityFilter === 'All' || risk.entitiesImpacted.find(e => e.id === Number(entityFilter)) != null) &&
        (categoryFilter === 'All' || risk.category?.id === Number(categoryFilter)) &&
        (statusFilter === 'All' || risk.status === Number(statusFilter)) &&
        (prioritisationFilter === 'All' || risk.prioritisation === Number(prioritisationFilter)) &&
        (riskAssessmentFilter === 'All' || (risk.sourceAssessment?.id ?? 0) === Number(riskAssessmentFilter)) &&
        (
            riskGroupFilter === RiskGroupFilters.GLOBAL_RISKS ||
            (riskGroupFilter === RiskGroupFilters.MY_RISKS && !risk.isEntityRisk && risk.createdBy === loggedOnUserProfile?.id) ||
            (riskGroupFilter === RiskGroupFilters.ENTITY_RISKS && (checkUserIsEntityHead(risk.entitiesImpacted, loggedOnUserProfile) || checkUserIsEntityCorrespondent(risk.entitiesImpacted, loggedOnUserProfile))) ||
            (riskGroupFilter === RiskGroupFilters.ENTITY_RISKS_NOT_SUBMITTED && risk.status === RiskStatus.IDENTIFIED && risk.isEntityRisk && (checkUserIsEntityHead(risk.entitiesImpacted, loggedOnUserProfile)))
        )
    )
    return sortRisks(_filteredRisks, riskSortType)
}

export function sortRisks(risks: Risk[], riskSortType: RiskSortType) {
    switch (riskSortType) {
        case RiskSortType.SortDateCreatedDesc:
            return risks.sort((a, b) => (a.submittedDate == null && b.submittedDate == null) ? (a.createdDate > b.createdDate ? -1 : 1) : ((b.submittedDate == null || a.submittedDate! > b.submittedDate) ? -1 : 1));
        case RiskSortType.SortDateCreatedAsc:
            return risks.sort((a, b) => (a.submittedDate == null && b.submittedDate == null) ? (a.createdDate > b.createdDate ? 1 : -1) : ((b.submittedDate == null || a.submittedDate! > b.submittedDate) ? 1 : -1));
        case RiskSortType.SortNameDesc:
            return risks.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1);
        case RiskSortType.SortNameAsc:
            return risks.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
        default:
            return risks;
    }
}

export function isNullOrWhiteSpace(str: string | null | undefined) {
    if (str == null) return true
    return Boolean(str) === false || Boolean(str.trim()) === false
}
