import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux'
import loggedOnUserProfile from './loggedOnUserProfile';
import serverAppConfig from './ServerAppConfig';
import riskRelatedTables from './riskRelatedTables';
import appConfig from './appConfig';
import cmdbUsers from './cmdbUsers';
import cmdbUsersId from './cmdbUsersId';

export const store = configureStore({
  reducer: {
    loggedOnUserProfile: loggedOnUserProfile,
    appConfig: appConfig,
    serverAppConfig: serverAppConfig,
    riskRelatedTables: riskRelatedTables,
    cmdbUsers: cmdbUsers,    
    cmdbUsersId: cmdbUsersId
  },
})

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()