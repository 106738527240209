import { AddCircleOutline, ArrowRight, Send, ModeEditOutline, DeleteOutline, EditRoad, Pending, Cancel, Done, DoneAll, VisibilityOff, Visibility } from "@mui/icons-material";
import { Paper, Table, TableBody, TableRow, TableCell, Stack, Divider, Typography, ButtonGroup, Button, List, ListItem, ListItemText, IconButton, Grid } from "@mui/material";
import { FormEditMode, RiskStatus, RiskActionStatus, SeverityDescription, MitigationColor, SeverityColor, PrioritisationColor } from "../../helpers/Constants";
import { formatShortDateString, truncateTextToWhiteSpace, getRatingDescription, checkUserIsRiskChampion, showConfirmBox, showInputBox, getRiskPrioritisationZoneString, showAlertInfo } from "../../helpers/Utils";
import { VerticalScrollableComponent } from "../../layouts/VerticalScrollableComponent";
import { useAppSelector } from "../../store/store";
import { Risk, RiskAction } from "../../helpers/Interfaces";
import { deleteRiskAction, submitActionPlan, submitActionPlanValidation, submitActionPlanImplementation, approveActionPlanImplementation, rejectActionPlanImplementation } from "../../api-services/RiskApi";
import { CannotSubmitIncompleteActionPlanImplementation, CanOnlyDeleteDraftOrRejectedRiskAction, NoPendingActionValidationToSubmitActionPlanValidation, SubmitActionPlanRequiresOneOrMoreDraftRiskActions, SubmitActionPlanRequiresZeroRejectedRiskAction } from "../../helpers/BusinessRules";
import { useState } from "react";

interface Props {
    risk: Risk
    selectedRiskAction: RiskAction | null | undefined
    onRiskChange: (id: number, risk: Risk) => void
    onRiskActionChange: (id: number, risk: Risk, actionId: number) => void
    onChangeRiskActionDisplay: (action: RiskAction | null, implementationEditmode: boolean, formEditMode: FormEditMode | null) => void
}

export function RiskActionsWidget(props: Props) {
    // state independent variables
    const loggedOnUserProfile = useAppSelector(state => state.loggedOnUserProfile.value);
    const riskRelatedTables = useAppSelector(state => state.riskRelatedTables.value);
    const isChampion = loggedOnUserProfile == null ? false : checkUserIsRiskChampion(props.risk, loggedOnUserProfile)
    const isSponsor = loggedOnUserProfile == null ? false : props.risk.sponsor === loggedOnUserProfile.id
    //state
    const [showMetrics, setShowMetrics] = useState(true)


    function handleConfirmDeleteAction(actionIdToDelete: number) {
        showConfirmBox("Are you sure you want to delete this Action from the Risk Mitigation Action Plan? \n This action cannot be undone.", "Confirm Delete")
            .then((result) => {
                if (result.isConfirmed)
                    deleteRiskAction(props.risk, actionIdToDelete).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }

    function handleConfirmSubmitActionPlan() {
        showConfirmBox("You will not be able to make changes after submitting. Are you sure you want to Submit this Action Plan to the Risk Sponsor for approval?", "Confirm Action Plan Submit")
            .then((result) => {
                if (result.isConfirmed)
                    submitActionPlan(props.risk).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }

    function handleConfirmSubmitActionPlanValidation() {
        showConfirmBox("You will not be able to make changes after submitting this Validation. Are you sure you want to continue?", "Confirm Validation Submit")
            .then((result) => {
                if (result.isConfirmed)
                    submitActionPlanValidation(props.risk).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }

    function handleConfirmSubmitActionPlanImplementation() {
        showConfirmBox("You will not be able to make changes after submitting. Are you sure you want to Submit this Implementation to the Risk Sponsor for approval?", "Confirm Action Plan Implementation Submit")
            .then((result) => {
                if (result.isConfirmed)
                    submitActionPlanImplementation(props.risk).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }

    function handleConfirmApproveImplementationSponsor() {
        showConfirmBox("Are you sure you want to Approve this Implementation?", "Confirm")
            .then((result) => {
                if (result.isConfirmed)
                    approveActionPlanImplementation(props.risk).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }

    function handleConfirmRejectImplementationSponsor() {
        showInputBox("Comments are required to reject this mitigation implementation", "Action Plan Implementation Rejection Comments", "please enter comments", "Reject")
            .then((result) => {
                if (result.value != null && result.value !== '')
                    rejectActionPlanImplementation(props.risk, result.value).then(result => {
                        props.onRiskChange(result.id, result);
                    })
            })
    }

    return loggedOnUserProfile &&
        <Paper className="Widget" style={{ width: '360px' }}>
            <Table  >
                <TableBody>
                    <TableRow >
                        <TableCell valign='middle' align='center' style={{ width: 100, paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 5 }}>
                            <Stack direction="row" spacing={1} justifyContent='center' alignItems='center' divider={<Divider orientation="vertical" flexItem classes={{ wrapper: "PaneDividerWrapper" }} />}>
                                <IconButton size="small" onClick={() => setShowMetrics(!showMetrics)} title={showMetrics ? "Hide Metrics" : "Show Metrics"}>
                                    {showMetrics ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                <Typography style={{ paddingTop: 5 }} align="left" fontSize={13} color="textSecondary" >Target Date: {props.risk.responseDate && <span style={{ color: 'red' }}>{formatShortDateString(props.risk.responseDate, false)}</span>}</Typography>
                                <ButtonGroup size="small" variant="contained" color="info">
                                    <Button onClick={() => props.onChangeRiskActionDisplay(null, false, FormEditMode.CREATE)} title="Add New Action"
                                        disabled={!isChampion || props.risk.status === RiskStatus.ACTION_PLAN_SUBMITTED || props.risk.status === RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED}>
                                        <AddCircleOutline />&nbsp;New Action
                                    </Button>
                                </ButtonGroup>
                            </Stack>
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                            <VerticalScrollableComponent viewPortHeightOffset={516 - (showMetrics ? 0 : 188)}>
                                <List style={{ padding: 0 }}>
                                    {props.risk.riskActions.map(action =>
                                        <ListItem divider={false} button key={action.id} classes={{ selected: "SelectedMenu" }} selected={props.selectedRiskAction?.id === action.id} >
                                            {action.status === RiskActionStatus.APPROVED && <Done fontSize="medium" color={props.selectedRiskAction?.id === action.id ? "secondary" : "primary"} />}
                                            {action.status === RiskActionStatus.REJECTED && <Cancel fontSize="medium" color={props.selectedRiskAction?.id === action.id ? "secondary" : "primary"} />}
                                            {action.status === RiskActionStatus.DRAFT && <ArrowRight fontSize="medium" color={props.selectedRiskAction?.id === action.id ? "secondary" : "primary"} />}
                                            {action.status === RiskActionStatus.SUBMITTED && <Pending fontSize="medium" color={props.selectedRiskAction?.id === action.id ? "secondary" : "primary"} />}
                                            {action.status === RiskActionStatus.IMPLEMENTATION_APPROVED && <DoneAll fontSize="medium" color={props.selectedRiskAction?.id === action.id ? "secondary" : "primary"} />}
                                            &nbsp;&nbsp;&nbsp;<ListItemText style={{ width: '100%' }} onClick={() => props.onChangeRiskActionDisplay(action, false, null)} primary={truncateTextToWhiteSpace(action.name, 50)} title={action.name} />
                                            {(props.risk.status === RiskStatus.ASSIGNED_TO_CHAMPION || props.risk.status === RiskStatus.ACTION_PLAN_REJECTED || props.risk.status === RiskStatus.ACTION_PLAN_APPROVED) &&
                                                <>
                                                    <IconButton id={action.id + ''} color="info" edge="end" onClick={() => props.onChangeRiskActionDisplay(action, false, FormEditMode.EDIT)} disabled={!isChampion || (action.status !== RiskActionStatus.DRAFT && action.status !== RiskActionStatus.REJECTED && action.status !== RiskActionStatus.APPROVED)} title='Edit Action' >
                                                        <ModeEditOutline />
                                                    </IconButton>
                                                    <IconButton id={action.id + ''} color="info" edge="end" onClick={() => handleConfirmDeleteAction(action.id)} disabled={!isChampion || !CanOnlyDeleteDraftOrRejectedRiskAction(action).validate()} title='Delete Action' >
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </>
                                            }
                                            {(props.risk.status === RiskStatus.ACTION_PLAN_APPROVED || props.risk.status === RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR) &&
                                                <IconButton id={action.id + ''} color="info" edge="end" onClick={() => props.onChangeRiskActionDisplay(action, true, FormEditMode.EDIT)} disabled={!isChampion || action.status !== RiskActionStatus.APPROVED} title='Update Action Implementation' >
                                                    <EditRoad />
                                                </IconButton>
                                            }
                                        </ListItem>
                                    )}
                                </List>
                            </VerticalScrollableComponent>
                        </TableCell >
                    </TableRow>
                    {showMetrics &&
                        <TableRow >
                            <TableCell align='center' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 5 }}>
                                <Grid spacing={1} container  >
                                    <Grid item xs={12} >
                                        <Stack direction="row" spacing={1} alignItems='start'>
                                            <Typography align="center" fontSize={14} style={{ padding: 3, width: '24px', color: '#FFFFFF', backgroundColor: SeverityColor[props.risk.severity] }}>{props.risk.severity}</Typography>
                                            <Typography align="left" fontSize={12} style={{ padding: 5 }} color="textSecondary" >Current Severity: {SeverityDescription[props.risk.severity]}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Stack direction="row" spacing={1} alignItems='start'>
                                            <Typography align="center" fontSize={14} style={{ padding: 3, width: '24px', color: '#FFFFFF', backgroundColor: MitigationColor[props.risk.mitigation] }} title={getRatingDescription(props.risk.mitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}>{props.risk.mitigation}</Typography>
                                            <Typography align="left" fontSize={12} style={{ padding: 5 }} color="textSecondary" >Current Mitigation</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Stack direction="row" spacing={1} alignItems='start'>
                                            <Typography align="center" fontSize={14} style={{ padding: 3, width: '24px', color: '#FFFFFF', backgroundColor: MitigationColor[props.risk.targetMitigation ?? props.risk.mitigation] }} title={getRatingDescription(props.risk.targetMitigation ?? props.risk.mitigation, riskRelatedTables.ratingDescriptions, "MITIGATION")}>{props.risk.targetMitigation ?? props.risk.mitigation}</Typography>
                                            <Typography align="left" fontSize={12} style={{ padding: 5 }} color="textSecondary" >Target Mitigation</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Stack direction="row" spacing={1} alignItems='start'>
                                            <Typography align="center" fontSize={14} style={{ padding: 3, width: '24px', color: '#FFFFFF', backgroundColor: PrioritisationColor[props.risk.prioritisation] }}>{props.risk.prioritisation}</Typography>
                                            <Typography align="left" fontSize={12} style={{ padding: 5 }} color="textSecondary" >Current Prioritisation: {getRiskPrioritisationZoneString(props.risk.prioritisation)}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Stack direction="row" spacing={1} alignItems='start'>
                                            <Typography align="center" fontSize={14} style={{ padding: 3, width: '24px', color: '#FFFFFF', backgroundColor: PrioritisationColor[props.risk.actionPlanImplementationTargetPrioritisation!] }}>{props.risk.actionPlanImplementationTargetPrioritisation}</Typography>
                                            <Typography align="left" fontSize={12} style={{ padding: 5 }} color="textSecondary" >Target Prioritisation: {getRiskPrioritisationZoneString(props.risk.actionPlanImplementationTargetPrioritisation!)}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </TableCell >
                        </TableRow>
                    }
                    <TableRow style={{ verticalAlign: 'bottom', height: 100, overflowY: 'clip' }}>
                        <TableCell align='center' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 5, paddingBottom: 5 }}>
                            {isChampion && (props.risk.status === RiskStatus.ASSIGNED_TO_CHAMPION || props.risk.status === RiskStatus.ACTION_PLAN_REJECTED) &&
                                <Button variant='contained' size='small' color='secondary' title="Submit action plan for sponsor validation"
                                    disabled={!SubmitActionPlanRequiresOneOrMoreDraftRiskActions(props.risk).validate() || !SubmitActionPlanRequiresZeroRejectedRiskAction(props.risk).validate()} onClick={() => handleConfirmSubmitActionPlan()}><Send /> &nbsp;Submit Action Plan</Button>
                            }
                            {isSponsor && props.risk.status === RiskStatus.ACTION_PLAN_SUBMITTED &&
                                <Button variant='contained' size='small' color='secondary' disabled={!NoPendingActionValidationToSubmitActionPlanValidation(props.risk).validate()} onClick={() => handleConfirmSubmitActionPlanValidation()} title="Send Validation back to Champion"><Send /> &nbsp;Submit Validation</Button>
                            }
                            {props.risk.status === RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR && props.risk.actionPlanImplementationRejectionComments != null &&
                                <>
                                    <Button variant='contained' color="info" style={{ marginBottom: 5 }} onClick={() => showAlertInfo(props.risk.actionPlanImplementationRejectionComments ?? '', "Sponsor Comments")}>View Sponsor Rejection Comments</Button>
                                    <hr style={{ border: '1px solid #eeeeee' }} />
                                </>
                            }
                            {isChampion && (props.risk.status === RiskStatus.ACTION_PLAN_APPROVED || props.risk.status === RiskStatus.ACTION_PLAN_IMPLEMENTATION_REJECTED_BY_SPONSOR) &&
                                <Button variant='contained' size='small' color='secondary' disabled={!CannotSubmitIncompleteActionPlanImplementation(props.risk).validate()} onClick={() => handleConfirmSubmitActionPlanImplementation()} title="Submit Action Plan Implementation"><Send /> &nbsp;Submit Plan Implementation</Button>
                            }
                            {isSponsor && props.risk.status === RiskStatus.ACTION_PLAN_IMPLEMENTATION_SUBMITTED &&
                                <>
                                    <Typography align="center" color="red" style={{ marginBottom: 5 }}>Implementation Validation</Typography>
                                    <Button variant='contained' size='small' color='success' onClick={() => handleConfirmApproveImplementationSponsor()} title={"Accept Action Plan Implementation"}> &nbsp;Accept</Button>&nbsp;&nbsp;&nbsp;
                                    <Button variant='contained' size='small' color='secondary' onClick={() => handleConfirmRejectImplementationSponsor()} title="Reject Action Plan Implementation"> &nbsp;Reject</Button>
                                </>
                            }
                        </TableCell >
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
}