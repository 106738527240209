import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CMDBUser } from '../helpers/Interfaces';

interface CmdbUsersState {
  value: Map<string, CMDBUser>
}

export const cmdbUsers = createSlice({
  name: 'cmdbUsers',
  initialState: {
    value: new Map<string, CMDBUser>(),
  } as CmdbUsersState,
  reducers: {
    setCmdbUsers: (state, action: PayloadAction<Map<string, CMDBUser>>) => {
      state.value = action.payload
    },
  },
})

export const { setCmdbUsers } = cmdbUsers.actions;
export default cmdbUsers.reducer;